import axios from "axios";
import Qs from "qs";
//import highlight from "@/components/highlight/highlight.vue";
import fiche from "@/components/posts/fiche.vue";
import { useFilterStore } from "@/store";

const storyMixin = {
	props: {
		post: {},
		post_id: 0,
		middle: {
			type: Boolean,
			default: true,
		},
		editrule: {
			type: Boolean,
			default: true,
		},
		showfiche: {
			type: Boolean,
			default: true,
		},
		includetohighlight: {},
		searchtohighlight: {
			type: [String, Array],
			default: null,
		},
		mastertags: {},
		checkedallstories: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			translateResult: null,
			loadingTranslate: false,
			postTitle: "",
			postDescription: "",
			videoTranscript: null,
		};
	},
	components: {
		//highlight: highlight,
		fiche: fiche,
	},
	deactivated() {
		// return $this.destroy(); jquery stuffs
	},
	mounted() {
		this.panel = useFilterStore().panel;
		this.highlightWords();
	},
	methods: {
		highlightWords() {
			this.postTitle = this.post.post_content && this.post.post_content.title ? this.post.post_content.title : "";
			this.postDescription = this.post.post_content && this.post.post_content.description ? this.post.post_content.description : "";
			if (this.includetohighlight) {
				this.highlightWord(this.includetohighlight, "Include");
			}
			if (this.searchtohighlight) {
				this.highlightWord(Array.isArray(this.searchtohighlight) ? this.searchtohighlight : [this.searchtohighlight], "Search");
			}
		},
		highlightWord(words, type, picture = "") {
			let strict = false;
			let formattedWord = [];
			words.forEach((word) => {
				if (word.substr(0, 1) === '"' && word.substr(-1, 1) === '"') {
					strict = true;
					word = word.replace(/"/g, "");
				}

				let collationRx = /([aáàâäæãå]+)|([eéèêë]+)|([iíìîï]+)|([oóòôöœõø]+)|([uúùûü]+)|([yýÿ]+)|([cç]+)|([nñ]+)/g;
				formattedWord.push(word.replace(collationRx, this.collationFn));
			});

			let regexWord = [];
			if (strict === true) {
				let punctuations = "[\\]\\[.!? :;,)(#/\r\\n]?";
				let middlepunctuations = "[\\]\\[.!? :;,)(#/\r\\n]{1,2}";
				let strictregex = "(?<![\\w\\d])";
				formattedWord.forEach((value) => {
					if (/\s/.test(value)) {
						let spaced = value.split(" ");
						let readyWord = spaced.join(middlepunctuations);
						regexWord.push(strictregex + punctuations + readyWord + punctuations + strictregex);
					} else {
						regexWord.push(strictregex + punctuations + value + punctuations + strictregex);
					}
				});
			} else {
				regexWord.push("(" + formattedWord.join("|") + ")");
			}

			let regex = new RegExp(regexWord.join("|"), "ugmi");

			if (picture) {
				if (words.length > 0) {
					if (picture.match(regex) !== null) {
						return true;
					}
				}
				return false;
			} else {
				if (strict === true && words.length > 0) {
					let span = '&nbsp;<span class="highlight' + type + '">' + "$&" + "</span>&nbsp;";
					this.postTitle = this.postTitle.replace(regex, span);
					this.postDescription = this.postDescription.replace(regex, span);
				} else if (words.length > 0) {
					let span = '<span class="highlight' + type + '">' + "$&" + "</span>";
					this.postTitle = this.postTitle.replace(regex, span);
					this.postDescription = this.postDescription.replace(regex, span);
				}
			}
		},
		collationFn(_, a, e, i, o, u, y, c, n, d, ss, th) {
			if (a) return "[aáàâäæãå]".repeat(a.length);
			if (e) return "[eéèêë]".repeat(e.length);
			if (i) return "[iíìîï]".repeat(i.length);
			if (o) return "[oóòôöœõø]".repeat(o.length);
			if (u) return "[uúùûü]".repeat(u.length);
			if (y) return "[yýÿ]".repeat(y.length);
			if (c) return "[cç]".repeat(c.length);
			if (n) return "[nñ]".repeat(n.length);
			if (d) return;
			if (ss) return;
			if (th) return;
		},
		feelingObject: function (feeling) {
			return {
				feeling: "dynvibe" + feeling,
			};
		},
		getHighlight(post) {
			let panel = useFilterStore().panel;
			return post.highlights ? post.highlights.filter((highlight) => highlight["panel"] === panel).length > 0 : false;
		},
		getDate: function (string, type) {
			if (!string.endsWith("+0000") && !string.endsWith("Z")) {
				// FIXME : need ES fix. Date have sometimes +0000, or Z (youtube) or nothing
				string += "+0000";
			}
			let date = new Date(string);

			if (type === "time") {
				let hour = date.getHours();
				let minutes = date.getMinutes();
				let seconds = date.getSeconds();
				return (
					(hour < 10 ? "0" : "") + hour + ":" + (minutes < 10 ? "0" : "") + minutes + ":" + (seconds < 10 ? "0" : "") + seconds
				);
			} else if (type === "date") {
				let year = date.getFullYear();
				let month = date.toLocaleString("default", { month: "long" });
				let day = date.getDate();
				return day + " " + month + " " + year;
			}
		},
		translatePost() {
			if (this.translateResult) {
				this.translateResult = false;
			} else {
				this.loadingTranslate = true;
				this.videoTranscript = false;
				let textToTranslate = /*this.post.content.title* + */ this.post.post_content;
				let params = { textToTranslate: textToTranslate };
				axios
					.post(this.$backendUrl + "translate", Qs.stringify(params))
					.then((response) => {
						this.loadingTranslate = false;
						this.translateResult = response.data.data.textTranslate.text;
					})
					.catch(() => (this.loadingTranslate = true));
			}
		},
		showTranscript() {
			if (this.videoTranscript) {
				this.videoTranscript = false;
			} else {
				this.translateResult = false;
				this.videoTranscript = this.post.video_transcript;
			}
		},
	},
};

export default storyMixin;
