<template>
	<div v-if="$route.name !== 'login'" id="test-vue" class="wrapper">
		<menuAdmin />
		<!-- Page Content Holder -->
		<div id="content" class="col-xs-12">
			<router-view> </router-view>

			<div id="fiche-modal"></div>
			<div id="highlight-modal"></div>
			<div id="mediacontent-modal"></div>
		</div>
		<GPTPopover v-if="$route.name === 'Posts'" />

		<Footer />
	</div>
	<div v-else>
		<div id="content" class="col-xs-12">
			<router-view> </router-view>
		</div>
	</div>
</template>
<script>
import menuAdmin from "@/components/globalVue/menuAdmin.vue";
import Footer from "@/components/globalVue/Footer.vue";
import GPTPopover from "@/components/globalVue/GPT/GPTPopover.vue";
import { useFilterStore } from "@/store";

export default {
	name: "Base",
	components: {
		GPTPopover,
		menuAdmin,
		Footer,
	},
	created: function () {
		//useFilterStore().resetUnifiedFilters(true);
		this.$store.resetParameters();

		if (useFilterStore().loupeFiltersExpireDate !== "" && this.$moment().isAfter(useFilterStore().loupeFiltersExpireDate)) {
			useFilterStore().resetLoupeFilters();
		}

		let params = typeof this.$store.parameters === "object" ? this.$store.parameters : JSON.parse(this.$store.parameters);
		params.type = params.type ? params.type : "Posts";

		let dateToday = new Date();
		dateToday = dateToday.toISOString().split("T")[0];
		let oneYearFromNow = new Date();
		oneYearFromNow.setFullYear(oneYearFromNow.getFullYear() - 1);
		oneYearFromNow = oneYearFromNow.toISOString().split("T")[0];
		useFilterStore().setDate(useFilterStore().date ? useFilterStore().date : { gte: oneYearFromNow, lte: dateToday });
	},
};
</script>
<style scoped></style>
