import { createRouter, createWebHistory } from "vue-router";
import axios from "axios";
//import store from "../store";
import { useMainStore } from "@/store";
import Qs from "qs";

function checkToken(to, from, next) {
	const mainStore = useMainStore();

	if (
		mainStore.jwt == "" &&
		mainStore.username == "" &&
		mainStore.is_admin == false &&
		mainStore.is_external == false &&
		mainStore.is_client == false
	) {
		window.location.href = "/login";
		return;
	}

	let acl = to.meta.roles || [];
	var goodAcl = false;
	if (acl.includes("admin") && mainStore.is_admin == true) {
		goodAcl = true;
	} else if (acl.includes("external") && mainStore.is_external == true && mainStore.is_client == false) {
		goodAcl = true;
	} else if (acl.includes("client") && mainStore.is_client == true) {
		goodAcl = true;
	}

	let backendUrl = "";
	if (process.env.NODE_ENV === "development") {
		backendUrl = "http://dev.detect.com/detect/";
	} else if (process.env.NODE_ENV === "staging") {
		backendUrl = "https://stagingback.dynvibe.com/detect/";
	} else {
		backendUrl = "https://api.dynvibe.com/detect/";
	}

	axios
		.post(
			backendUrl + "checkJwtToken",
			Qs.stringify({
				jwt: mainStore.jwt,
				username: mainStore.username,
			})
		)
		.then((response) => {
			if (response.data.logged === true && goodAcl) {
				mainStore.dispatch("setJwt", response.data.token);
				next();
			} else {
				next({ name: "unauthorized" });
			}
		})
		.catch((error) => {
			if (error.code != "ERR_CANCELED") {
				mainStore.dispatch("setJwt", "");
				mainStore.dispatch("setUsername", "");
				mainStore.dispatch("setIsAdmin", false);
				mainStore.dispatch("setIsExternal", false);
				mainStore.dispatch("setIsClient", false);

				next({ name: "login" });
			}
		});
}

const routes = [
	{ path: "/", redirect: "/profiles" },
	{
		path: "/profiles",
		name: "Profiles",
		component: () => import("@/views/ProfilesPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin", "external"] },
	},
	{
		path: "/trend-deactivation",
		name: "Trend Deactivation",
		component: () => import("@/views/TrendUnvalidationPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin", "external"] },
	},
	{
		path: "/posts",
		name: "Posts",
		component: () => import("@/views/PostsPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin", "external"] },
	},
	{
		path: "/author",
		name: "Author",
		component: () => import("@/views/AuthorPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin", "external"] },
	},
	{
		path: "/newin",
		name: "Incoming",
		component: () => import("@/views/IncomingPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin", "external"] },
	},
	{
		path: "/report",
		name: "Report",
		component: () => import("@/views/ReportPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin", "external"] },
	},
	{
		path: "/rules",
		name: "Rules",
		component: () => import("@/views/rulesPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin"] },
	},
	{
		path: "/panels",
		name: "Panels",
		component: () => import("@/views/PanelsMiddlePage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin"] },
	},
	{
		path: "/createPanel/:panelName?",
		name: "Create Panel",
		component: () => import("@/views/CreatePanelPage.vue"),
		props: true,
		beforeEnter: checkToken,
		meta: { roles: ["admin"] },
	},
	{
		path: "/categories",
		name: "Categories",
		component: () => import("@/views/categoriesPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin"] },
	},
	{
		path: "/segments",
		name: "Segments",
		component: () => import("@/views/SegmentsPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin"] },
	},
	{
		path: "/trend-validation",
		name: "Trend Validation",
		component: () => import("@/views/TrendValidationPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin", "external"] },
	},
	{
		path: "/explore",
		name: "Explore",
		component: () => import("@/views/ExplorePage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin", "external"] },
	},
	{
		path: "/barometer",
		name: "Barometer",
		component: () => import("@/views/Barometer.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin", "external"] },
	},
	{
		path: "/campaign",
		name: "Campaign",
		component: () => import("@/views/Campaign.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin", "external"] },
	},
	// {
	// 	path: "/profiles-validation",
	// 	name: "Profiles Validation",
	// 	component: () => import("@/views/ProfilesValidationPage.vue"),
	// 	beforeEnter: checkToken,
	// 	meta: { roles: ["admin"] },
	// },
	// {
	// 	path: "/authors-crawl",
	// 	name: "Authors Crawl",
	// 	component: () => import("@/views/AuthorsCrawl.vue"),
	// 	beforeEnter: checkToken,
	// 	meta: { roles: ["admin"] },
	// },
	{
		path: "/animated",
		name: "Animated",
		component: () => import("@/views/AnimatedChartsPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin"] },
	},
	{
		path: "/login",
		name: "login",
		component: () => import("@/views/LoginPage.vue"),
	},
	{
		path: "/signin",
		name: "signin",
		component: () => import("@/views/SigninPage.vue"),
		meta: { roles: ["admin"] },
	},
	{
		path: "/update-password",
		name: "update password",
		component: () => import("@/views/UpdatePasswordPage.vue"),
		meta: { roles: ["admin", "external"] },
	},
	{
		path: "/account-management",
		name: "account management",
		component: () => import("@/views/AccountManagementPage.vue"),
		meta: { roles: ["admin", "external"] },
	},
	{
		path: "/unauthorized",
		name: "unauthorized",
		component: () => import("@/views/Unauthorized.vue"),
	},
	{
		path: "/check", // for devs
		name: "Check",
		component: () => import("@/views/CheckPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin"] },
	},
	{
		path: "/check-data", // for users
		name: "Check Data",
		component: () => import("@/views/CheckDataPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin"] },
	},
	{
		path: "/check-mastertagging", // for users
		name: "Check Mastertagging",
		component: () => import("@/views/MastertaggingCheck.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin"] },
	},
	{
		path: "/custom-dashboard-page/:id?", // for users
		name: "Custom Dashboard Page",
		component: () => import("@/views/CustomDashboardPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin", "client"] },
		props: (route) => ({
			id: route.query.id,
		}),
	},
	{
		path: "/dashboards-list", // for users
		name: "Dashboards List Page",
		component: () => import("@/views/DashboardsListPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin", "client"] },
	},
	{
		path: "/data-management", // for users
		name: "Data Management Page",
		component: () => import("@/views/DataManagementPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin"] },
	},
	{
		path: "/search-image", //
		name: "Vector",
		component: () => import("@/views/VectorPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin"] },
	},
	{
		path: "/check-sources", //
		name: "Check Sources",
		component: () => import("@/views/CheckSourcesPage.vue"),
		beforeEnter: checkToken,
		meta: { roles: ["admin"] },
	},
];

const router = createRouter({
	history: createWebHistory("/"),
	routes,
});

export default router;
