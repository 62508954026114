import { defineStore } from "pinia";
//import { mapStores } from "pinia";
//import createPersistedState from "vuex-persistedstate";
import moment from "moment";

export const useFilterStore = defineStore("filterStore", {
	state: () => {
		return {
			isReset: false,
			panel: "",
			segment: "",
			author: "",
			category: "",
			subcategory: "",
			trend: "",
			mastertag: "",
			advancedFilters: {
				mastertags: [],
				includeWords: [[]],
				excludeWords: [],
			},
			loupeFilters: {
				gender: [],
				age: [],
				country: [],
				sources: [],
			},
			loupeFiltersExpireDate: "",
			date: {
				gte: moment().subtract(3, "month").toDate().toISOString().split("T")[0],
				lte: moment().toDate().toISOString().split("T")[0],
			},
			datecompare: {
				gte: moment().subtract(3, "month").toDate().toISOString().split("T")[0],
				lte: moment().toDate().toISOString().split("T")[0],
			},
			search: "",
		};
	},
	getters: {},
	actions: {
		resetLoupeFilters() {
			this.loupeFilters = {
				gender: [],
				age: [],
				country: [],
				sources: [],
			};
			this.loupeFiltersExpireDate = "";
		},
		resetUnifiedFilters(startup = false) {
			this.panel = "";
			this.segment = "";
			this.author = "";
			this.category = "";
			this.subcategory = "";
			this.trend = "";
			this.mastertag = "";
			this.advancedFilters = {
				mastertags: [],
				includeWords: [[]],
				excludeWords: [],
			};
			this.search = "";
			// Reset only if we are not resetting via the App.vue initial startup reset
			this.isReset = !startup;
		},
		setPanel(value) {
			this.panel = value;
			this.isReset = false;
		},
		setSegment(value) {
			this.segment = value;
			this.isReset = false;
		},
		setAuthor(value) {
			this.author = value;
			this.isReset = false;
		},
		setCategory(value) {
			this.category = value;
			this.isReset = false;
		},
		setSubcategory(value) {
			this.subcategory = value;
			this.isReset = false;
		},
		setTrend(value) {
			this.trend = value;
			this.isReset = false;
		},
		setMastertag(value) {
			this.mastertag = value;
			this.isReset = false;
		},
		setAdvancedFilters(value) {
			this.advancedFilters.includeWords = value.includeWords.map((x) => [...x]);
			this.advancedFilters.excludeWords = [...value.excludeWords];
			this.advancedFilters.mastertags = [...value.mastertags];
			this.isReset = false;
		},
		setSearch(value) {
			this.search = value;
			this.isReset = false;
		},
		setLoupeFilters(value) {
			this.loupeFilters = value;
			this.loupeFilters.gender = [...value.gender];
			this.loupeFilters.age = [...value.age];
			this.loupeFilters.country = [...value.country];
			this.loupeFilters.sources = [...value.sources];
		},
		setDate(value) {
			this.date = value;
		},
		setDateCompare(value) {
			this.datecompare = value;
		},
		setLoupeFiltersExpireDate(value) {
			this.loupeFiltersExpireDate = value;
		},
	},
	persist: true,
});

export const useMainStore = defineStore("mainStore", {
	//plugins: [createPersistedState()],
	state: () => {
		return {
			parameters: {},
			panel: "",
			segment: "",
			datecompare: {
				gte: moment().subtract(3, "month").toDate().toISOString().split("T")[0],
				lte: moment().toDate().toISOString().split("T")[0],
			},
			pageName: "",
			is_admin: false, //  can edit data
			is_external: false, // dynviber or not
			is_client: false,
			client_name: "",
			userId: 0,
			//mastertagTop100: "",
			expiredFilters: "",
			jwt: "",
			username: "",
			cancelTokens: [],
		};
	},
	getters: {},
	actions: {
		resetParameters() {
			this.parameters = {};
		},
		addToParameters(payload) {
			if (this.parameters) {
				this.parameters[payload.key] = payload.value;
				//let storeParameters = JSON.parse(state.parameters);
				//storeParameters[payload.key] = payload.value;
				//state.parameters = JSON.stringify(storeParameters);
			}
		},
		removeFromParameters(key) {
			if (this.parameters) {
				delete this.parameters[key];
				//let storeParameters = JSON.parse(state.parameters);
				//delete storeParameters[key];
				//state.parameters = JSON.stringify(storeParameters);
			}
		},
		setPanel(value) {
			this.panel = value;
		},
		setSegment(value) {
			this.segment = value;
		},
		setPageName(value) {
			// TODO : check if really called ?
			this.pageName = value;
		},
		setDateCompare(value) {
			this.datecompare = value;
		},
		setParameters(value) {
			this.parameters = JSON.parse(JSON.stringify(value));
		},
		setIsAdmin(value) {
			this.is_admin = value;
		},
		setIsExternal(value) {
			this.is_external = value;
		},
		setIsClient(value) {
			this.is_client = value;
		},
		setClientName(value) {
			this.client_name = value;
		},
		setUserId(value) {
			this.userId = value;
		},
		setExpiredFilters(value) {
			this.expiredFilters = value;
		},
		setJwt(value) {
			this.jwt = value;
		},
		setUsername(value) {
			this.username = value;
		},
		ADD_CANCEL_TOKEN(token) {
			this.cancelTokens.push(token);
		},
		CANCEL_PENDING_REQUESTS() {
			// Cancel all request where a token exists
			this.cancelTokens.forEach((request /*i*/) => {
				console.log(request);
				if (request.signal) {
					//request.cancel();
					console.log("abort", request);
					request.abort();
				}
			});

			// Reset the cancelTokens store
			this.cancelTokens = [];
		},
		dispatch(action, value) {
			this[action](value);
		},
	},
	persist: true,

	/*persist: {
		storage: localStorage,
		beforeRestore: () => {
			console.log("Before hydration...");
		},
		afterRestore: () => {
			console.log("After hydration...");
		},
	},*/
});
