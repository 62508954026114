import { createApp } from "vue";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
// TEST CI
import App from "./App.vue";
import router from "./router";
import { useMainStore } from "./store/index";

import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import More from "highcharts/highcharts-more";
import stockInit from "highcharts/modules/stock";
import exportingInit from "highcharts/modules/exporting";
import exportData from "highcharts/modules/export-data";
import VueSmoothScroll from "vue3-smooth-scroll";
import heatmap from "highcharts/modules/heatmap";
exportingInit(Highcharts);
More(Highcharts);
stockInit(Highcharts);
exportData(Highcharts);
heatmap(Highcharts);

import "@/assets/css/front/icon.css";
import "@/assets/css/flag-icon.min.css";
import { library, dom } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
library.add(fas);
import { fab } from "@fortawesome/free-brands-svg-icons";
library.add(fab);
import { far } from "@fortawesome/free-regular-svg-icons";
library.add(far);
dom.watch();
window.$ = window.jQuery = require("jquery");
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "@/assets/css/front/newFront.css";
import dynvibewordcloud from "@/components/vueCharts/DynvibeWordCloud.vue";
import bigstory from "@/components/posts/bigStory.vue";
const app = createApp(App);

// global variables / function for all components
// usage for axios : this.$axios
const moment = require("moment");
import axios from "axios";
import Qs from "qs";
import xregexp from "xregexp";

// console.log(process.env.NODE_ENV);
// let pendingAxios = false;
let allAxios = [];

function checkNested(obj, level, ...rest) {
	if (obj === undefined) return false;
	if (rest.length == 0 && Object.prototype.hasOwnProperty.call(obj, level)) return true;
	// Object.prototype.hasOwnProperty.call(obj, level);
	return checkNested(obj[level], ...rest);
}
axios.interceptors.request.use(
	async (config) => {
		//let source = axios.CancelToken.source();
		let controller = new AbortController();

		// Set cancel token on this request
		// config.cancelToken = source.token;
		config.signal = controller.signal;

		// Add to vuex to make cancellation available from anywhere
		//app.config.globalProperties.$store.dispatch("ADD_CANCEL_TOKEN", source);
		app.config.globalProperties.$store.dispatch("ADD_CANCEL_TOKEN", controller);

		let request =
			config.url +
			(checkNested(config, "params", "id") ? config.params.id : "") +
			(checkNested(config, "params", "data", "function") ? config.params.data.function : "");

		if (config.url.includes("topsFlops")) {
			request += config.params.type;
		}
		if (config.url.includes("getStats")) {
			request += config.params.level;
		}
		if (config.url.includes("graphTrends")) {
			request += config.params.aggType;
		}
		if (config.url.includes("checkOcrOrSpeechSynthesis")) {
			request += config.params.parameters.transcript ? "transcript" : "ocr";
		}

		if (config.url.includes("translateArray") || config.url.includes("generatePanelSegmentReport")) {
			let queryString = new URLSearchParams(config.data);
			let cancelId = queryString.get("cancelAxiosId");
			request += cancelId;
		}

		let item = allAxios.find((item) => item.request === request);
		if (item) {
			//item.source.cancel();
			item.controller.abort();
			allAxios = allAxios.filter(function (elem) {
				return elem !== item;
			});
		}

		// while (allAxios.includes(request)) {
		// 	// if a request is still pending, we wait
		// 	// console.log("waiting");
		// 	await new Promise((resolve) => setTimeout(resolve, 100));
		// }
		// not loading, we continue
		//allAxios.push({ request: request, source: source });
		allAxios.push({ request: request, controller: controller });

		//console.log(JSON.stringify(allAxios));
		return config;
	},
	(error) => {
		return Promise.reject(error);
	}
);
axios.interceptors.response.use(
	async (response) => {
		let request =
			response.config.url +
			(checkNested(response.config, "params", "id") ? response.config.params.id : "") +
			(checkNested(response.config, "params", "data", "function") ? response.config.params.data.function : "");
		if (response.config.url.includes("topsFlops")) {
			request += response.config.params.type;
		}
		if (response.config.url.includes("getStats")) {
			request += response.config.params.level;
		}
		if (response.config.url.includes("graphTrends")) {
			request += response.config.params.aggType;
		}
		if (response.config.url.includes("checkOcrOrSpeechSynthesis")) {
			request += response.config.params.parameters.transcript ? "transcript" : "ocr";
		}
		if (response.config.url.includes("translateArray") || response.config.url.includes("generatePanelSegmentReport")) {
			request += response.data.cancelAxiosId;
		}

		for (var i = 0; i < allAxios.length; i++) {
			if (allAxios[i].request === request) {
				await allAxios.splice(i, 1);
				break;
			}
		}

		return response;
	},
	async (error) => {
		if (error.code !== undefined && error.code === "ERR_CANCELED") {
			return Promise.reject(error);
		}

		let request =
			error.response.config.url +
			(checkNested(error.response.config, "params", "id") ? error.response.config.params.id : "") +
			(checkNested(error.response.config, "params", "data", "function") ? error.response.config.params.data.function : "");
		if (error.response.config.url.includes("topsFlops")) {
			request += error.response.config.params.type;
		}
		if (error.response.config.url.includes("getStats")) {
			request += error.response.config.params.level;
		}
		if (error.response.config.url.includes("graphTrends")) {
			request += error.response.config.params.aggType;
		}
		if (error.response.config.url.includes("checkOcrOrSpeechSynthesis")) {
			request += error.response.config.params.parameters.transcript ? "transcript" : "ocr";
		}

		if (error.response.config.url.includes("translateArray") || error.response.config.url.includes("generatePanelSegmentReport")) {
			let cancelId = error.response.data.cancelAxiosId !== undefined ? error.response.data.cancelAxiosId : "";
			request += cancelId;
		}

		for (var i = 0; i < allAxios.length; i++) {
			if (allAxios[i].request === request) {
				await allAxios.splice(i, 1);
				break;
			}
		}

		return Promise.reject(error);
	}
);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

app.config.globalProperties.$axios = axios;
app.config.globalProperties.$Qs = Qs;
app.config.globalProperties.$moment = moment;
app.config.globalProperties.$xregexp = xregexp;

// app.config.compilerOptions.isCustomElement = (tag) => tag === "dynvibewordcloud";
// TODO : config file for DEV / PROD ?

if (process.env.NODE_ENV == "development") {
	app.config.globalProperties.$backendUrl = "http://dev.detect.com/detect/";
} else if (process.env.NODE_ENV == "staging") {
	app.config.globalProperties.$backendUrl = "https://stagingback.dynvibe.com/detect/";
} else {
	app.config.globalProperties.$backendUrl = "https://api.dynvibe.com/detect/";
}

if (["production", "staging"].includes(process.env.NODE_ENV)) {
	window.markerConfig = {
		destination: "62ebef4ffb36beb625d6f9f0",
		source: "snippet",
	};
	!(function (e, r /*a*/) {
		if (!e.__Marker) {
			e.__Marker = {};
			var t = [],
				n = { __cs: t };
			[
				"show",
				"hide",
				"isVisible",
				"capture",
				"cancelCapture",
				"unload",
				"reload",
				"isExtensionInstalled",
				"setReporter",
				"setCustomData",
				"on",
				"off",
			].forEach(function (e) {
				n[e] = function () {
					var r = Array.prototype.slice.call(arguments);
					r.unshift(e), t.push(r);
				};
			}),
				(e.Marker = n);
			var s = r.createElement("script");
			(s.async = 1), (s.src = "https://edge.marker.io/latest/shim.js");
			var i = r.getElementsByTagName("script")[0];
			i.parentNode.insertBefore(s, i);
		}
	})(window, document);
}
import mixin from "@/mixins/mixin.js";
app.mixin(mixin);
app.provide("picture_host", "https://s3.fr-par.scw.cloud/dynvibe/instagram_images/");
app.component("FontAwesomeIcon", FontAwesomeIcon);
app.component("Dynvibewordcloud", dynvibewordcloud);
app.component("Bigstory", bigstory);

app.use(pinia).use(router).use(VueSmoothScroll).use(HighchartsVue);

router.isReady().then(() => {
	app.mount("#app");
});
//app.use(pinia).use(router).use(VueSmoothScroll).use(HighchartsVue);

app.config.globalProperties.$store = useMainStore(pinia); // need to be call here
