<template>
	<transition name="slide" mode="out-in">
		<div v-if="isVisible" id="gpt-popover">
			<div style="margin-left: 10px; margin-bottom: 10px; margin-top: 10px; height: 5%">
				<label class="labelRadio"
					>Open discussion
					<input type="radio" :checked="!shouldShowQueryWordInput" @click="shouldShowQueryWordInput = false" />
					<span class="checkmark"></span>
				</label>
				<label class="labelRadio"
					>Predefined functions
					<input type="radio" :checked="shouldShowQueryWordInput" @click="shouldShowQueryWordInput = true" />
					<span class="checkmark"></span>
				</label>
				<div class="pull-right">
					<button
						class="btn"
						style="margin-top: -10px"
						@click="
							isVisible = false;
							shouldShowExamplePosts = false;
						"
					>
						X
					</button>
				</div>
			</div>
			<div v-if="shouldShowQueryWordInput" style="height: 95%">
				<div style="height: 5%; margin-left: 5px">
					<gselect
						:options="predefinedQueryTypes"
						:value="activeQueryType"
						:inside-classes="['dynvibeSelect']"
						:reduce="(item) => item.value"
						:get-option-label="(item) => item.name"
						@update="setActiveQueryType"
					></gselect>
				</div>
				<div class="gpt-chat-area">
					<transition-group name="fade">
						<div
							v-for="message in getVisibleMessageHistoryPredefined()"
							:key="message"
							:class="[
								{ 'gpt-popover-message-system': message.author === 'system' },
								{ 'gpt-popover-message-user': message.author === 'user' },
							]"
						>
							{{ message.message }}
							<div v-if="message.examplePostIds" class="ai-example-button" @click="showExamplePosts(message.examplePostIds)">
								Examples <i class="fas fa-search"></i>
							</div>
						</div>
					</transition-group>
					<div v-if="loading" class="dynvibeLoading"></div>
				</div>
				<div class="row" style="height: 10%">
					<div class="col-lg-10">
						<input v-model="userInput" class="gpt-input" placeholder="Query word..." type="text" />
					</div>
					<div class="col-lg-2">
						<button type="button" class="dynvibeButton" style="margin-top: 14px" @click="handleMessage">Send</button>
					</div>
				</div>
			</div>
			<div v-else style="height: 95%">
				<div class="col-lg-12 dynvibeTitleBox" style="height: 5%">
					<h2>Open discussion</h2>
				</div>
				<div class="gpt-chat-area">
					<transition-group name="fade">
						<div
							v-for="message in getVisibleMessageHistoryOpen()"
							:key="message"
							:class="[
								{ 'gpt-popover-message-system': message.author === 'system' },
								{ 'gpt-popover-message-user': message.author === 'user' },
							]"
						>
							{{ message.message }}
							<div v-if="message.examplePostIds" class="ai-example-button" @click="showExamplePosts(message.examplePostIds)">
								Examples <i class="fas fa-search"></i>
							</div>
						</div>
					</transition-group>
					<div v-if="loading" class="dynvibeLoading"></div>
				</div>
				<div class="row" style="height: 10%">
					<div class="col-lg-10">
						<input v-model="userInput" class="gpt-input" placeholder="Search for..." type="text" />
					</div>
					<div class="col-lg-2">
						<button type="button" class="dynvibeButton" style="margin-top: 14px" @click="handleMessage">Send</button>
					</div>
				</div>
			</div>
		</div>
	</transition>
	<transition name="slide" mode="out-in">
		<button v-if="!isVisible" class="gpt-button" @click="showWindow()">Dynvibe Copilot</button>
	</transition>
	<!-- Example posts -->
	<transition name="fade">
		<div v-if="shouldShowExamplePosts" class="gpt-example-posts-window">
			<div class="gpt-example-posts-container">
				<div class="pull-right" style="margin-top: 10px; margin-right: 5px">
					<button class="btn" style="margin-top: -10px" @click="shouldShowExamplePosts = false">
						<i class="fa fa-arrow-right" />
					</button>
				</div>
				<div v-if="!loading" class="col-lg-12 dynvibeListPosts dynvibeBox" style="margin: 10px 0">
					<bigstory
						v-for="(post, postId) in showcasedExamplePosts"
						:ref="'story-' + postId"
						:key="postId"
						:showfiche="false"
						:post="post"
						:post_id="postId"
						:editrule="false"
					></bigstory>
				</div>
				<div v-else class="dynvibeLoading" style="min-height: 295px"></div>
			</div>
		</div>
	</transition>
</template>

<script>
import axios from "axios";
import { useFilterStore } from "@/store";

export default {
	name: "GPTPopover",
	data() {
		return {
			isVisible: false,
			loading: false,
			shouldShowExamplePosts: false,
			shouldShowQueryWordInput: false,
			userInput: "",
			activeQueryType: "free_question",
			predefinedQueryTypes: [
				{ name: "Mentioned with other brands", value: "brand_mentions_with_other_brands" },
				{ name: "Summary", value: "summarization" },
				{ name: "Pain points", value: "pain_points_and_negative_sentiment" },
				{ name: "Posts mentioning it", value: "post_mention_and_sentiment" },
			],
			/*
			message: the shown message
			author: "system" or "user", to define who sent the message
			type: "open" or "predefined", to define in which chat area the message should be shown
			examplePostIds: the sample posts used to give the AI results. Not present in the initial system messages.
			 */
			totalMessageHistory: [
				{ message: "Hello, how can I help you today?", author: "system", type: "open" },
				{
					message: "Those are some predefined functions. Select one and type in the word you want to search information for.",
					author: "system",
					type: "predefined",
				},
			],
			showcasedExamplePosts: [],
		};
	},
	methods: {
		showWindow() {
			this.isVisible = !this.isVisible;
		},
		setActiveQueryType(queryType) {
			this.activeQueryType = queryType;
			if (queryType !== "free_question") this.shouldShowQueryWordInput = true;
		},
		async handleMessage() {
			if (this.userInput === "") return;
			// Verify if we are doing an open discussion
			if (this.activeQueryType !== "free_question" && !this.shouldShowQueryWordInput) this.activeQueryType = "free_question";
			// Verify if we did not choose a predefined function and we still send a message (do not send it)
			if (this.activeQueryType === "free_question" && this.shouldShowQueryWordInput) return;
			// Send a request to the GPT API
			try {
				this.loading = true;
				const res = await axios.get("https://dynvibe-gpt-hsw4lvx7iq-ew.a.run.app/gpt-query/" + this.activeQueryType, {
					params: {
						query: this.userInput,
						// Add in the select fields all the fields that are used in the filters, then post_content
						// And finally all the other fields that you want to get too
						select_fields: "profile_panels,profile_segments,post_content,post_url",
						filters: JSON.stringify(this.buildGPTFilters()),
						date_range: JSON.stringify(this.buildGPTDateRange()),
					},
				});
				this.loading = false;
				// Add user message to message history
				this.totalMessageHistory.push({
					message: this.userInput,
					author: "user",
					type: this.activeQueryType === "free_question" ? "open" : "predefined",
				});
				this.userInput = "";
				// Then send the response as a chatbot message and add to message history
				this.totalMessageHistory.push({
					message: res.data.message,
					author: "system",
					type: this.activeQueryType === "free_question" ? "open" : "predefined",
					examplePostIds: res.data.fields.ids,
				});
			} catch (e) {
				this.loading = false;
				console.error(e);
			}
		},
		getVisibleMessageHistoryOpen(nbMessages = 5) {
			return this.totalMessageHistory.filter((message) => message.type === "open").slice(-nbMessages);
		},
		getVisibleMessageHistoryPredefined(nbMessages = 5) {
			return this.totalMessageHistory.filter((message) => message.type === "predefined").slice(-nbMessages);
		},
		buildGPTFilters() {
			const filters = {};
			if (useFilterStore().panel !== undefined) {
				filters.profile_panels = useFilterStore().panel;
			}

			if (useFilterStore().segment !== undefined) {
				filters.profile_segments = useFilterStore().segment;
			}

			return filters;
		},
		buildGPTDateRange() {
			return { gte: useFilterStore().date.gte, lte: useFilterStore().date.lte };
		},
		showExamplePosts(posts) {
			// Get the posts using their ids and add to the showcasedExamplePosts list
			this.showcasedExamplePosts = [];
			this.getData("getPostsByIds", { ids: posts });
		},
		treatResponse(response) {
			for (const postId in response.data.posts) {
				this.showcasedExamplePosts.push(response.data.posts[postId]._source);
			}
			this.showcasedExamplePosts = this.showcasedExamplePosts.map((post) => {
				//This is due to the storyMixin using a {title: string, description: string} format
				post.post_content = { title: post.post_content };
				return post;
			});
			this.shouldShowExamplePosts = true;
		},
	},
};
</script>

<style scoped>
#gpt-popover {
	position: absolute;
	z-index: 9999;
	right: 0;
	width: 35vw;
	height: calc(100vh - 35px);
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
	transition: all 0.3s;
}

.gpt-button {
	font-family: "Montserrat", "Arial", "Helvetica", "sans-serif";
	position: fixed;
	z-index: 9999;
	margin-top: 10px;
	right: 15px;
	background-color: #3879b5;
	border: none;
	color: white;
	padding: 8px 8px;
	text-align: center;
	text-decoration: none;
	display: inline-block;
	font-size: 16px;
	border-radius: 5%;
	cursor: pointer;
}

.gpt-button:hover {
	color: #fff;
	background-color: #2ba3d0;
}

.activeButton {
	background-color: #1f4a69 !important;
}

.gpt-button-area {
	height: 10%;
	margin-top: 15px;
	padding-right: 5px;
	padding-left: 5px;
	padding-bottom: 15px;
	background-color: #fefefe;
	border: #797878;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.gpt-input {
	width: 95%;
	padding: 5px 7px;
	margin-top: 10px;
	margin-left: 10px;
	margin-bottom: 10px;
	box-sizing: border-box;
	border: 3px solid #3879b5;
	border-radius: 4px;
	-webkit-transition: 0.5s;
	transition: 0.5s;
	outline: none;
}

.gpt-chat-area {
	height: 80%;
	overflow-y: scroll;
	margin-top: 20px;
	margin-right: 5px;
	margin-left: 5px;
	padding-right: 5px;
	padding-left: 5px;
	background-color: #fefefe;
	border: #797878;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.gpt-popover-message-system {
	background-color: #3879b5;
	border-radius: 10px;
	padding: 10px;
	margin: 10px;
	color: white;
	font: 14px "Montserrat", "Arial", "Helvetica", "sans-serif";
}

.gpt-popover-message-user {
	background-color: #3cd0cc;
	border-radius: 10px;
	padding: 10px;
	margin: 10px;
	text-align: right;
	color: white;
	font: 14px "Montserrat", "Arial", "Helvetica", "sans-serif";
}

.labelRadio {
	display: inline;
	position: relative;
	padding-left: 25px;
	padding-right: 25px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 14px;
	font-weight: 700;
	color: #8e8e8e;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

/* Hide the browser's default checkbox */
.labelRadio input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 15px;
	width: 15px;
	background-color: #fff;
	border-radius: 50%;
	border: 1px solid #33d1cd;
}

/* On mouse-over, add a grey background color */
.labelRadio:hover input ~ .checkmark {
	background-color: rgba(51, 209, 205, 0.2);
}

/* When the checkbox is checked, add a blue background */
.labelRadio input:checked ~ .checkmark {
	background-color: #33d1cd;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}

/* Show the checkmark when checked */
.labelRadio input:checked ~ .checkmark:after {
	display: block;
}

.ai-example-button {
	margin-top: 10px;
	margin-bottom: 10px;
}

.gpt-example-posts-window {
	z-index: 999;
	position: absolute;
	width: 65vw;
	height: calc(100vh - 35px);
	overflow-y: scroll;
	background-color: #fff;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

/* Animations */

/* Slide */
.slide-enter-active {
	transition: all 0.3s ease-out;
}

.slide-leave-active {
	transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-enter-from,
.slide-leave-to {
	transform: translateX(60vw);
}

/* Fade */
.fade-enter-active {
	transition: all 0.3s ease-out;
}

.fade-leave-active {
	transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter-from,
.fade-leave-to {
	opacity: 0;
}
</style>
