import Qs from "qs";
import { useFilterStore, useMainStore } from "@/store";

import confirm from "@/components/globalVue/Confirm.vue";
import gselect from "@/components/globalVue/Select.vue";

const mixin = {
	components: { confirm, gselect },
	mounted() {
		if (this.incomingModal) {
			let parameters = {
				...this.$parent.$parent.trendfilters,
				...this.$parent.$parent.semanticfilters,
				...this.$parent.$parent.modalkeywords,
			};
			this.loadComponent(parameters);
		} else if (this.explorePage) {
			let parameters = {
				...this.$parent.$parent.filters,
			};
			this.loadComponent(parameters);
		} else {
			// ajouter date et les filtre loupe
			let parameters = {
				...this.$store.parameters,
				date: useFilterStore().date,
				...useFilterStore().loupeFilters,
			};
			delete parameters.language;
			this.loadComponent(parameters);
		}
	},
	data() {
		return {
			dialogLoad: null,
			confirmColor: "",
			confirmTitle: "",
			confirmType: "",
			confirmMessage: "",
			confirmChoice1: "",
			confirmChoice2: "",
			languageslist: [
				{ id: "ar", value: "Arabic" },
				{ id: "zh-cn", value: "Chinese (Simplified)" },
				{ id: "zh-tw", value: "Chinese (Traditional)" },
				{ id: "en", value: "English" },
				{ id: "fr", value: "French" },
				{ id: "de", value: "German" },
				{ id: "el", value: "Greek" },
				{ id: "it", value: "Italian" },
				{ id: "ja", value: "Japanese" },
				{ id: "ko", value: "Korean" },
				{ id: "pt", value: "Portuguese" },
				{ id: "pt-br", value: "Portuguese - Brazil" },
				{ id: "ru", value: "Russian" },
				{ id: "es", value: "Spanish" },
			],
			countrieslist: [
				{ id: "ar", value: "Argentina" },
				{ id: "au", value: "Australia" },
				{ id: "at", value: "Austria" },
				{ id: "be", value: "Belgium" },
				{ id: "br", value: "Brasil" },
				{ id: "ca", value: "Canada" },
				{ id: "cl", value: "Chile" },
				{ id: "cn", value: "China" },
				{ id: "co", value: "Colombia" },
				{ id: "hr", value: "Croatia" },
				{ id: "cu", value: "Cuba" },
				{ id: "cz", value: "Czech Republic" },
				{ id: "da", value: "Denmark" },
				{ id: "eg", value: "Egypt" },
				{ id: "fr", value: "France" },
				{ id: "de", value: "Germany" },
				{ id: "gr", value: "Greece" },
				{ id: "hk", value: "Hong-Kong" },
				{ id: "hu", value: "Hungaria" },
				{ id: "in", value: "India" },
				{ id: "id", value: "Indonesia" },
				{ id: "ie", value: "Ireland" },
				{ id: "il", value: "Israel" },
				{ id: "it", value: "Italia" },
				{ id: "jp", value: "Japan" },
				{ id: "kr", value: "Korea" },
				{ id: "my", value: "Malaysia" },
				{ id: "mx", value: "Mexico" },
				{ id: "ma", value: "Morocco" },
				{ id: "tn", value: "Tunisia" },
				{ id: "nl", value: "Netherlands" },
				{ id: "np", value: "Nepal" },
				{ id: "nz", value: "New-Zealand" },
				{ id: "ng", value: "Nigeria" },
				{ id: "no", value: "Norway" },
				{ id: "pl", value: "Poland" },
				{ id: "pt", value: "Portugal" },
				{ id: "qa", value: "Qatar" },
				{ id: "ru", value: "Russia" },
				{ id: "sa", value: "Saudi Arabia (السعودية)" },
				{ id: "sg", value: "Singapor" },
				{ id: "za", value: "South Africa" },
				{ id: "es", value: "Spain" },
				{ id: "se", value: "Sweden" },
				{ id: "ch", value: "Switzerland" },
				{ id: "tw", value: "Taiwan" },
				{ id: "th", value: "Thailand" },
				{ id: "tr", value: "Turkey" },
				{ id: "uk", value: "UK" },
				{ id: "ua", value: "Ukraine" },
				{ id: "ae", value: "United Arab Emirates (الإمارات)" },
				{ id: "us", value: "USA" },
				{ id: "ve", value: "Venezuela" },
				{ id: "vn", value: "Vietnam" },
			],
			graphColor: [
				"#32D1CD",
				"#7CB5EC",
				"#99C584",
				"#FFD500",
				"#F3A344",
				"#E35656",
				"#EFBBFF",
				"#A60CA6",
				"#0457A9",
				"#FF0081",
				"#8D864A",
				"#6C4111",
				"#D9D4A5",
				"#232323",
				"#460446",
				"#11520D", // Limit
				"#F1C40F",
				"#A0D6B4",
				"#36802D",
				"#243F5B",
				"#8D864A",
				"#845422",
				"#4BCC2E",
				"#BE29EC",
				"#FF0081",
				"#0C457D",
				"#036",
				"#FFDAB9",
				"#DAA520",
				"#F66",
				"#66CDAA",
				"#FFF68F",
				"#B0E0E6",
				"#8A2BE2",
				"#696966",
				"#000",
			],
			graphColorUnknown: "#C4C4C4",
			// pendingAxios: false,
		};
	},
	props: {
		incomingModal: {
			default: false,
		},
		explorePage: {
			default: false,
		},
		barometrePage: {
			default: false,
		},
		campaignPage: {
			default: false,
		},
	},
	methods: {
		loadComponent(/*parameters*/) {
			//console.log(parameters);
			// To be overwritten
		},
		getData(url, params = [], condition = "", arrayFormat = "brackets") {
			const mainStore = useMainStore();
			params["jwt"] = mainStore.jwt;
			this.$axios
				.get(this.$backendUrl + url, {
					params: params,
					paramsSerializer: function (params) {
						return Qs.stringify(params, {
							arrayFormat: arrayFormat,
						});
					},
				})
				.then((response) => {
					this.treatResponse(response, condition);
				})
				.catch(function (error) {
					if (error.code !== undefined && error.code === "ERR_CANCELED") {
						console.log("axios canceled");
						return;
					}
					//catchError;
					console.log("Error axios getData");
					console.log(error);
				});
		},
		postData(url, params = [], condition = "" /*, catchError = "", errorMessage = ""*/) {
			const mainStore = useMainStore();

			let p = params;
			if (!(params instanceof FormData)) {
				params["jwt"] = mainStore.jwt;
				p = Qs.stringify(params);
			} else {
				p.append("jwt", mainStore.jwt);
			}
			this.$axios
				.post(this.$backendUrl + url, p)
				.then((response) => {
					this.pendingAxios = false;
					if (response.data) this.treatPostResponse(response, condition);
				})
				.catch(function (error) {
					if (error.code !== undefined && error.code === "ERR_CANCELED") {
						console.log("axios canceled");
						return;
					}
					// catchError(errorMessage);
					console.log("Error axios postData");
					console.log(error);
				});
		},
		storeWatcher(newValue, oldValue) {
			return newValue.valueOf() !== oldValue.valueOf();
		},
		confirmLoadingModal(status = "close", message = "In progress...") {
			this.confirmColor = "orange";
			this.confirmTitle = message;
			this.confirmType = "loading";
			status === "close" ? this.$refs.confirm.close() : this.$refs.confirm.open();
		},
		confirmInputAddModal(message) {
			this.confirmColor = "orange";
			this.confirmTitle = "Edit";
			this.confirmType = "edit";
			this.confirmMessage = message;
			this.$refs.confirm.open();
		},
		confirmMultiChoiceModal(message, choice1, choice2) {
			this.confirmColor = "orange";
			this.confirmTitle = "Choice";
			this.confirmType = "multichoice";
			this.confirmMessage = message;
			this.confirmChoice1 = choice1;
			this.confirmChoice2 = choice2;
			this.$refs.confirm.open();
		},
		confirmInputDeleteModal(message) {
			this.confirmColor = "red";
			this.confirmTitle = "Delete";
			this.confirmType = "edit";
			this.confirmMessage = message;
			this.$refs.confirm.open();
		},
		confirmErrorModal(message) {
			this.confirmLoadingModal();
			this.confirmColor = "red";
			this.confirmTitle = "ERROR";
			this.confirmType = "alert";
			this.confirmMessage = message;
			this.$refs.confirm.open();
		},
		confirmSuccessModal(message) {
			this.confirmLoadingModal();
			this.confirmColor = "green";
			this.confirmTitle = "SUCCESS";
			this.confirmType = "alert";
			this.confirmMessage = message;
			this.$refs.confirm.open();
		},
		darkenLightenColor(color, percent) {
			if (typeof color !== "undefined") {
				let num = parseInt(color.replace("#", ""), 16),
					amt = Math.round(2.55 * percent),
					R = (num >> 16) + amt,
					B = ((num >> 8) & 0x00ff) + amt,
					G = (num & 0x0000ff) + amt;
				return (
					"#" +
					(
						0x1000000 +
						(R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
						(B < 255 ? (B < 1 ? 0 : B) : 255) * 0x100 +
						(G < 255 ? (G < 1 ? 0 : G) : 255)
					)
						.toString(16)
						.slice(1)
				);
			}
			return color;
		},
		formatToKM(value) {
			if (!value) {
				return 0;
			}

			if (value > 999 && value < 1000000) {
				return Math.round(value / 1000) + "K"; // convert to K for number from > 1000 < 1 million
			} else if (value >= 1000000 && value < 1000000000) {
				return Math.round(value / 1000000) + "M"; // convert to M for number from > 1 million
			} else if (value >= 1000000000) {
				return Math.round(value / 1000000000) + "B"; // convert to B for number from > 1 billion
			} else {
				return Math.round(value);
			}
		},

		formatToKMPercent(value, evo = true, type = "") {
			if (!value) {
				return this.formatToKM(0) + " %";
			}
			if (evo === true) {
				return value > 0 ? "+" + this.formatToKM(value) + " %" : this.formatToKM(value) + " %";
			} else {
				if (type == "engagement") {
					if (value % 1 == 0) {
						return value.toFixed() + " %";
					} else {
						return parseFloat(value).toFixed(1) + " %";
					}
				} else {
					return this.formatToKM(value) + " %";
				}
			}
		},
		excerpt(value, length = 200) {
			if (value !== null) {
				return value.substr(0, length);
			}
			return "";
		},
		checkImageExists(url, callback) {
			const img = new Image();
			img.src = url;

			if (img.complete) {
				callback(true);
			} else {
				img.onload = () => {
					callback(true);
				};

				img.onerror = () => {
					callback(false);
				};
			}
		},
		translateWordcloud(listWord, fromLanguage = "", cancelAxiosId = "") {
			if (listWord.length > 0) {
				let params = { arrayToTranslate: listWord, fromLanguage: fromLanguage };
				if (cancelAxiosId !== "") {
					params.cancelAxiosId = cancelAxiosId;
				}
				this.postData("translateArray", params, "translateArray");
			}
		},
		findEmptyFields(fields) {
			let emptyKeys = [];
			for (let key in fields) {
				if (Object.prototype.hasOwnProperty.call(fields, key)) {
					let field = fields[key];
					if (field === null || field === undefined) {
						emptyKeys.push(key);
					} else if (Array.isArray(field)) {
						if (field.length === 0 || field.every((item) => item === "")) {
							emptyKeys.push(key);
						}
					} else if (typeof field === "string" && field === "") {
						emptyKeys.push(key);
					}
				}
			}
			return emptyKeys;
		},
		generateUniqueID() {
			return "xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
				let r = Math.random() * (16 | 0);
				let v = c == "x" ? r : (r & 0x3) | 0x8;
				return v.toString(16);
			});
		},
	},
};

export default mixin;
