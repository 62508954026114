<template>
	<div v-if="showFiche">
		<div class="text-center buttonFicheDiv">
			<button
				type="button"
				class="dynvibeButton buttonFiche"
				data-toggle="modal"
				data-target="#dynvibeFiche"
				:data-idprofile="idprofile"
				@click="loadModal"
			>
				Fiche
				<font-awesome-icon icon="portrait" />
			</button>
		</div>
		<div v-if="showModal">
			<!-- <teleport to="#fiche-modal"> -->
			<transition name="modal">
				<div class="modal-mask modal">
					<div class="modal-wrapper" @mousedown="showModal = false">
						<div class="modal-dialog modal-lg">
							<div class="modal-content" @mousedown.stop>
								<div class="modal-header">
									<div class="col-lg-12 dynvibeTitleBox dynvibeTitleModal">
										<h5 class="modal-title">
											Fiche Profile
											<button type="button" class="close" @click="showModal = false">
												<span aria-hidden="true">
													<i class="icon-x"></i>
												</span>
											</button>
										</h5>
									</div>
								</div>
								<div class="modal-body">
									<div class="dynvibeBox">
										<div class="row dynvibeRow">
											<div v-if="loading" class="dynvibeLoading"></div>
											<div v-else class="card">
												<div class="card-image">
													<div class="card-box-image">
														<img class="dynvibeArrayTopImg" :src="setImage(profile)" width="140" />
														<div class="card-box-author">
															<span class="value">
																{{ profile.profile_name }}
															</span>
														</div>
													</div>
													<div class="card-data">
														<div v-if="hasReach" class="card-box-view cards-box-reactions">
															<span class="title">Views</span> <br />
															<span class="value">{{ formatToKM(profile.reach) }}</span>
														</div>
														<div v-else class="card-box-view cards-box-reactions">
															<span class="title">Potential Reach</span> <br />
															<span class="value">{{ getPotReach }}</span>
														</div>
														<div class="card-box-reaction cards-box-reactions">
															<span class="title">Reactions</span> <br />
															<span class="value">{{ formatToKM(profile.reactions) }}</span>
														</div>
														<div class="card-box-engagement cards-box-reactions">
															<span class="title">Engagement</span> <br />
															<span class="value">{{
																formatToKMPercent(profile.engagement_rate, false, "engagement")
															}}</span>
														</div>
													</div>
												</div>
											</div>
											<div v-if="loading" class="dynvibeLoading"></div>
											<table v-else class="table">
												<tbody style="overflow-y: unset">
													<tr
														v-for="(element, i) in stats"
														:key="i"
														class="col-xs-12 source-box"
														:style="{ 'background-color': sourceColors[i] }"
													>
														<td class="col-xs-1 dynvibeStoryInfo">
															<a
																class="dynvibeProfilesLinkPosts"
																:href="
																	'https://www.' +
																	i +
																	'.com/' +
																	(i == 'youtube'
																		? 'channel/' + element.guid
																		: i == 'tiktok'
																		? '@' + element.username
																		: element.username)
																"
																target="_blank"
															>
																<font-awesome-icon :icon="['fab', i]"
															/></a>
														</td>
														<td class="col-xs-1">
															<a>@{{ element.username }}</a>
														</td>
														<td class="col-xs-1">
															<span class="tiny-light-text">avg engagement </span>
															<br />
															<span class="tiny-bold-text">{{ getStats(element.avgEngagement, true) }}</span>
														</td>
														<td class="col-xs-1">
															<span class="tiny-light-text">avg Reach </span>
															<br />
															<span v-if="i != 'instagram'" class="tiny-bold-text">{{
																getStats(element.avgReach)
															}}</span>
															<span v-else class="tiny-bold-text">N/A</span>
														</td>
														<td class="col-xs-1">
															<span class="tiny-light-text">avg Likes</span>
															<br />
															<span class="tiny-bold-text">{{ getStats(element.avgLikes) }}</span>
														</td>
														<td class="col-xs-1">
															<span class="tiny-light-text">avg Comments</span>
															<br />
															<span class="tiny-bold-text">{{ getStats(element.avgComments) }}</span>
														</td>
														<td class="col-xs-1">
															<span class="tiny-light-text">avg Shares</span>
															<br />
															<span class="tiny-bold-text">{{ getStats(element.avgShares) }}</span>
														</td>
														<td class="col-xs-1">
															<span class="tiny-light-text">Potential Reach</span>
															<br />
															<span class="tiny-bold-text">{{ formatToKM(element.followers) }}</span>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>

									<div class="dynvibeBox">
										<div class="row dynvibeRow">
											<div class="col-lg-12 dynvibeTitleBox">
												<h2>Medias</h2>
											</div>
										</div>
										<div v-if="sources.length > 1" class="row rowFilters">
											<div class="col-lg-12">
												<button
													class="dynvibeButton filterMediasButton"
													@click="
														selectedSource = 'all';
														mediaFilter();
													"
												>
													All
												</button>
												<button
													v-for="(source, index) in sources"
													:key="index"
													class="dynvibeButton filterMediasButton"
													@click="
														selectedSource = source;
														mediaFilter();
													"
												>
													{{ source }}</button
												>&nbsp;
											</div>
										</div>
										<div class="row dynvibeRow">
											<div class="col-lg-12">
												<div v-if="!loading && medias.length > 0">
													<ul>
														<li
															v-for="media in filteredMedias"
															:key="media._id"
															style="display: inline-block"
															class="filterMedia"
														>
															<a :href="getMediaUrl(media)" target="_blank" :style="getMediaStyle(media)">
															</a>
														</li>
													</ul>
												</div>
												<div v-if="!loading && medias.length === 0" class="noData">
													<p>No Data to display</p>
												</div>
												<div v-if="loading" class="dynvibeLoading"></div>
											</div>
										</div>
										<div class="row dynvibeRow">
											<div class="col-xs-6">
												<div class="wrapperKeyword">
													<dynvibewordcloud
														ref="ficheProfileKeyword"
														:url-to-get="urlKeyword()"
														title="Keywords"
														:editable="false"
														:clickable="false"
														:display-filter-language="true"
													></dynvibewordcloud>
												</div>
											</div>
											<div class="col-xs-6">
												<div class="wrapperKeyword">
													<dynvibewordcloud
														ref="ficheProfileMastertags"
														:url-to-get="urlMastertags()"
														title="Mastertags"
														:editable="false"
														:clickable="false"
														:translatable="false"
													></dynvibewordcloud>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</transition>
			<!-- </teleport> -->
			<!-- </portal> -->
		</div>
	</div>
</template>

<script>
import { useFilterStore } from "@/store";
export default {
	name: "Fiche",
	inject: ["picture_host"],
	props: {
		idprofile: {
			type: String,
			default: "",
		},
		middle: {
			type: Boolean,
			default: false,
		},
		isprofile: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			engagements: {},
			filtered: [],
			followers: {},
			loading: false,
			medias: [],
			profile: {},
			reaches: {},
			selectedSource: null,
			showFiche: false,
			showModal: false,
			sources: [],
			sourceColors: {
				instagram: "rgba(193,53,132,0.2)",
				twitter: "rgba(225,232,237,0.2)",
				tiktok: "rgba(20,23,26,0.2)",
				youtube: "rgba(255,0,0,0.2)",
				facebook: "rgba(66, 103, 178,0.2)",
			},
			stats: {},
			validImages: [],
		};
	},
	computed: {
		filteredMedias() {
			return this.medias.filter(
				(e) =>
					e.media !== "twitter" &&
					this.checkImage(e) &&
					this.validImagesContains(e) &&
					(this.getMediaSource(e) === this.selectedSource || this.selectedSource === "all")
			);
		},
		hasReach() {
			let reach = this.profile.reach ? this.profile.reach : 0;
			return reach !== 0;
		},
		getPotReach() {
			let potReach = 0;
			for (let i = 0; i < this.profile.source.length; i++) {
				potReach += parseInt(this.profile.source[i].followers);
			}

			return this.formatToKM(potReach);
		},
	},
	mounted() {
		this.selectedSource = "all";
		// if (store.admin === 1) {
		this.showFiche = true;
		// }
	},

	methods: {
		urlKeyword() {
			let url = "ficheProfileKeyword?idprofile=" + this.idprofile;

			if (this.selectedSource !== "all") {
				url += "&sources=" + this.selectedSource;
			}

			return url;
		},
		urlMastertags() {
			let url = "ficheProfileMastertags?idprofile=" + this.idprofile;

			if (this.selectedSource !== "all") {
				url += "&sources=" + this.selectedSource;
			}

			return url;
		},
		getStats(stats, percent = false) {
			if (stats) {
				if (percent === true) {
					if (stats % 1 == 0) {
						return stats.toFixed(0) + " %";
					} else {
						return parseFloat(stats).toFixed(1) + " %";
					}
				} else {
					return this.formatToKM(Math.round(stats));
				}
			} else {
				return 0;
			}
		},
		getMediaSource(media) {
			return media["media"];
		},
		getMediaUrl(media) {
			return media["url"];
		},
		getMediaPicture(media) {
			let mediaPicture = media["picture"];
			if (media["media"] === "twitter") {
				mediaPicture = mediaPicture.replace("_normal.", "_400x400");
				mediaPicture = mediaPicture.replace("size=bigger", "size=original");
			} else if (media["media"] === "instagram") {
				let picture = media["url"].replace("https://www.instagram.com/p/", "").replace("/", "");
				return [mediaPicture, this.picture_host + this.idprofile + "/source/" + picture + ".jpg"];
			} else if (media["media"] === "tiktok") {
				mediaPicture = this.picture_host + this.idprofile + "/source/tiktok/" + media["url"].split("/")[5] + ".jpg";
			}
			return mediaPicture;
		},
		getMediaStyle(media) {
			let picture = this.getMediaPicture(media);
			if (Array.isArray(picture)) {
				return (
					"background-image:url(" +
					picture[0] +
					"),url(" +
					picture[1] +
					");width: 180px;height: 180px;display: block;background-position: center;background-size: cover;"
				);
			} else {
				return (
					"background-image:url(" +
					picture +
					");width: 180px;height: 180px;display: block;background-position: center;background-size: cover;"
				);
			}
		},
		mediaFilter() {
			if (this.$refs.ficheProfileKeyword) {
				this.$refs.ficheProfileKeyword.changeUrl(this.urlKeyword());
			}
			if (this.$refs.ficheProfileMastertags) {
				this.$refs.ficheProfileMastertags.changeUrl(this.urlMastertags());
			}
		},
		treatPostResponse(response) {
			this.sources = response.data.mediasSources;
			if (response.data.medias) {
				this.medias = response.data.medias;

				this.medias = response.data.medias.filter((e) => e.media !== "twitter" && this.checkImage(e));
			}
			if (response.data.stats) {
				this.stats = response.data.stats.stats;
				this.profile = response.data.stats.profiles[Object.keys(response.data.stats.profiles)[0]];
			}

			// this.reaches = response.data.reaches;
			// this.engagements = response.data.engagements;
			// this.followers = response.data.followers;
			//console.log(response.data);
			this.selectedSource = "all";
			// this.mediaFilter();
			this.loading = false;
		},
		loadModal() {
			this.medias = [];
			this.showModal = true;
			this.loading = true;
			let params = {
				idprofile: this.idprofile,
			};
			// if (this.isprofile == false) {
			// 	params = { ...params, ...this.setParameters() };
			// }
			if (this.middle === true) {
				params["middle"] = this.middle;
			}
			// console.log(params);
			this.postData("ficheProfile", params);
		},
		setParameters() {
			let parameters = {};

			parameters.date = {
				gte: useFilterStore().date.gte,
				lte: useFilterStore().date.lte,
			};
			useFilterStore().loupeFilters.country.length > 0 ? (parameters.country = [...useFilterStore().loupeFilters.country]) : false;
			useFilterStore().loupeFilters.sources.length > 0 ? (parameters.sources = [...useFilterStore().loupeFilters.sources]) : false;
			useFilterStore().loupeFilters.gender && useFilterStore().loupeFilters.gender.length > 0
				? (parameters.gender = [...useFilterStore().loupeFilters.gender])
				: false;
			useFilterStore().loupeFilters.age && useFilterStore().loupeFilters.age.length > 0
				? (parameters.age = [...useFilterStore().loupeFilters.age])
				: false;
			useFilterStore().panel !== "" ? (parameters.panel = useFilterStore().panel) : false;
			useFilterStore().segment !== "" ? (parameters.segment = useFilterStore().segment) : false;
			// this.type === "ocr" ? (parameters.ocr = true) : false;
			// this.type === "transcript" ? (parameters.transcript = true) : false;
			useFilterStore().author !== "" ? (parameters.author = useFilterStore().author) : false;
			useFilterStore().category !== "" ? (parameters.category = useFilterStore().category) : false;
			useFilterStore().subcategory !== "" ? (parameters.subcategory = useFilterStore().subcategory) : false;
			useFilterStore().trend !== "" ? (parameters.trend = useFilterStore().trend) : false;
			useFilterStore().mastertag !== "" ? (parameters.mastertag = useFilterStore().mastertag) : false;
			useFilterStore().advancedFilters.mastertags.length > 0
				? (parameters.mastertags = [...useFilterStore().advancedFilters.mastertags])
				: false;
			useFilterStore().advancedFilters.includeWords.length > 0
				? (parameters.includeWords = useFilterStore().advancedFilters.includeWords.map((x) => [...x]))
				: false;
			useFilterStore().advancedFilters.excludeWords.length > 0
				? (parameters.excludeWords = [...useFilterStore().advancedFilters.excludeWords])
				: false;
			useFilterStore().search !== "" ? (parameters.search = useFilterStore().search) : false;
			return parameters;
		},
		avgReach() {
			let average = Object.values(this.stats).reduce((a, b) => a + b.avgReach, 0) / Object.values(this.stats).length;
			average = Math.round((average + Number.EPSILON) * 100) / 100;
			return this.formatToKM(average);
		},
		avgEngagement() {
			let average = Object.values(this.stats).reduce((a, b) => a + b.avgEngagement, 0) / Object.values(this.stats).length;
			average = Math.round((average + Number.EPSILON) * 100) / 100;
			return this.formatToKM(average);
		},

		totalFollowers() {
			let sum = Object.values(this.stats).reduce((a, b) => a + parseInt(b.followers), 0);

			return this.formatToKM(sum);
		},
		async checkImage(media) {
			let pictures = this.getMediaPicture(media);
			if (!Array.isArray(pictures)) {
				pictures = [pictures];
			}

			for (let i = 0; i < pictures.length; i++) {
				await this.checkImageExists(pictures[i], (exists) => {
					if (exists == true) {
						if (!this.validImages.includes(pictures[i])) {
							this.validImages.push(pictures[i]);
						}
					}
				});
			}
		},
		validImagesContains(media) {
			let pictures = this.getMediaPicture(media);
			if (!Array.isArray(pictures)) {
				pictures = [pictures];
			}

			for (let i = 0; i < pictures.length; i++) {
				if (this.validImages.includes(pictures[i])) {
					return true;
				}
			}
			return false;
		},
		setImage() {
			return this.picture_host + this.profile.profile_id + "/source/profile.jpg";
			// for (const i in this.profile.source) {
			// 	if (this.profile.source[i].media == "instagram") {
			// 		return this.picture_host + this.profile.profile_id + "/source/profile.jpg";
			// 	} else if (!this.profile.source[i].picture) {
			// 		continue;
			// 	}
			// 	let image = new Image();
			// 	image.src = this.profile.source[i].picture;

			// 	if (image.width > 0) {
			// 		return image.src;
			// 	}
			// }

			// return require("@/assets/img/default_profile_bigger.png");
		},
	},
};
</script>

<style scoped>
.dynvibeButton.buttonFiche {
	min-width: 0;
}
.wrapperKeyword {
	min-height: 100px;
}
.wrapperKeyword > #keywordProfile,
.wrapperKeyword > #mastertagProfile {
	width: 100%;
	height: 100%;
	min-height: 400px;
}
.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	color: black;
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.close {
	outline: 0;
}

.dynvibeBox {
	min-height: 300px;
}

.dynvibeTitleModal {
	padding-bottom: 10px;
	margin-bottom: 0;
}

.rowFilters {
	margin-bottom: 15px;
	margin-top: 15px;
}

.filterMediasButton {
	margin-right: 5px;
}

ul {
	list-style: none;
	padding-left: 0;
}

li {
	display: inline-block;
}

.filterMedia {
	margin: 5px;
}

.content1 {
	font-size: 16px;
	font-weight: 700;
	color: #ef402e;
	text-transform: uppercase;
}
.content {
	font-size: 16px;
	font-weight: 700;
	color: #23056b;
	text-transform: uppercase;
}

.dynvibeProfilesDetailsValue {
	font-size: 35px;
}

.card {
	flex: 1 1;
	position: relative;
}

.card-image {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-start;
	justify-content: center;
	text-align: center;
	position: relative;
	padding: 18px;
}

.card-image img {
	object-fit: contain;
	max-height: 140px;
	border-radius: 70px;
	border: 5px solid #d8d8d8;
	transition: all 0.3s;
	margin-top: 20px;
}

.card-image img:hover {
	border: 5px solid #33d1cd;
}

.card-box-author > .value {
	box-shadow: 1px 1px 3px rgb(0 0 0 / 25%);
	background: #fafafa;
	color: #7c7c7c;
	padding: 0px 9px;
	margin-top: 5px;
}

.card-box-author > .value > i {
	color: #ef402e;
}

.card-views {
	position: absolute;
	top: 8px;
	right: 16px;
}

.card-box-reaction,
.card-box-view,
.card-box-engagement {
	position: absolute;
	margin: 0;
	padding: 0.5rem;
	word-break: break-word;
	background-color: white;
}

.card-box-reaction > .title {
	color: #23056b;
	text-transform: uppercase;
	font-size: 15px;
}

.card-box-reaction > .value {
	color: #b5b5b5;
	text-transform: capitalize;
	font-size: 16px;
}

.card-box-view > .title {
	color: #33d1cd;
	text-transform: uppercase;
	font-size: 15px;
}

.card-box-view > .value {
	color: #b5b5b5;
	text-transform: capitalize;
	font-size: 16px;
}

.card-box-engagement > .title {
	color: #ef402e;
	text-transform: uppercase;
	font-size: 15px;
}

.card-box-engagement > .value {
	color: #b5b5b5;
	text-transform: capitalize;
	font-size: 16px;
}
.card-data {
	display: flex;
	flex-direction: column;
	width: 120px;
	position: relative;
	/* right: 30px; */
	right: 10px;
	gap: 10px;
	/* padding-top: 40px; */
	padding-top: 0;
	margin-left: 28px;
}
.cards-box-reactions {
	flex: 0;
	width: 150px;
	position: static;
	box-shadow: 0 0 5px rgba(0, 0, 0, 0.25);
}
.source-box {
	box-shadow: 0 0 5px rgb(0 0 0 / 25%);
}
.tiny-light-text {
	color: #8a95a0;
	font-size: 12px;
	position: relative;
}
.tiny-bold-text {
	font-weight: bold;
}
:deep(tbody) {
	height: auto;
}
:deep(table td[class*="col-"]) {
	float: none !important;
	width: 194px;
}
</style>
