<template>
	<div class="col-xs-12 dynvibeStory">
		<div class="col-xs-12 col-lg-12">
			<div v-if="middle" class="col-lg-1 col-xs-12">
				<input
					v-if="checkable && ACL.isAdmin()"
					class="dynvibeStoryCheckbox"
					name="dynvibeStoryCheckbox"
					type="checkbox"
					:value="post.url"
					:checked="checkedallstories"
					@change="
						$emit('listcheckedmt', {
							event: $event.target.checked,
							mastertag: post.mastertags,
							storieschecked: post_id,
						})
					"
				/>
				<span
					v-if="editrule && ACL.isAdmin()"
					:id="'buttonify_' + post_id"
					class="glyphicon glyphicon-pencil buttonify dynvibeCreateRule"
					style="cursor: pointer; margin-left: 10px; margin-top: 20px"
					@click="$emit('buttonify', { id: post_id })"
				></span>
			</div>
			<div class="col-lg-2 col-xs-12 dynvibeStoryInfo">
				<span style="display: inline-block">
					<span class="dynvibeStoryInfoDate">{{ getDate(post.post_date, "date") }}</span>
					<span class="dynvibeStoryInfoHour">{{ getDate(post.post_date, "time") }}</span>
				</span>
				<a :href="post.post_url" target="_blank">
					<font-awesome-icon :icon="['fab', post.post_media]" />
				</a>
			</div>
			<div v-if="middle" class="col-lg-1 col-xs-12 dynvibeStoryInfo">
				<img class="dynvibeArrayTopImg" :src="setImage(post)" width="60" height="60" />
			</div>
			<div v-if="middle">
				<span class="dynvibeStoryInfoUsername">
					<span
						data-action="Edit"
						data-value="post.profile_id"
						class="dynvibeTableActions dynvibeTableActionsEdit dynvibeModalAuthor"
						title="Edit"
						@click="editProfile(post.profile_id)"
						>{{ post.profile_name }}</span
					>
				</span>
			</div>

			<div v-if="post.profile_id" class="col-lg-9 col-xs-12 pull-right dynvibeSegmentsInfo" style="margin-top: 20px">
				<div class="col-lg-10">
					<div v-for="segment in post.profile_segments" :key="segment" class="dynvibePostSegment">
						{{ segment }}
					</div>
				</div>

				<!---
				<div v-if="!middle" class="col-lg-1">
					<highlight :ref="'highlight-' + post_id" :idpost="post_id" :prop-active="getHighlight(post)"></highlight>
				</div>
				-->
			</div>
		</div>
		<div class="col-xs-12 col-lg-12" style="margin-top: 20px">
			<div class="col-lg-2 col-xs-12 dynvibeStoryImg">
				<a
					:href="post.post_url"
					target="_blank"
					class="postPicture_big"
					:style="getMediaStyle(post)"
					:class="highlightpicture(post) === true ? 'ocr-image' : ''"
				></a>
				<div v-if="highlightpicture(post)" style="width: 200px; max-width: 100%">
					<span class="ocr-text">OCR</span>
				</div>
				<fiche v-if="showfiche" :idprofile="post.profile_id" :middle="middle"></fiche>
			</div>
			<div class="col-lg-8 col-xs-12">
				<div class="row dynvibeStoryContenu">
					<p>
						<!-- eslint-disable vue/no-v-html -->
						<span class="dynvibeToTranslateResult" v-html="postTitle"></span>
						<span class="dynvibeToTranslateResult" v-html="postDescription"></span>
						<!--eslint-enable-->
						<span class="row" style="width: 250px; padding: 0 10px">
							<span class="col-auto" style="padding-right: 10px">
								<button class="dynvibeTranslate dynvibeButton" @click="translatePost()">Translate</button>
							</span>
							<span v-if="hasTranscript" class="col-auto" style="padding-right: 10px">
								<button class="dynvibeTranslate dynvibeButton" @click="showTranscript()">Transcript</button>
							</span>
						</span>
						<span v-if="loadingTranslate" class="dynvibeLoading" style="min-height: 0"></span>
						<span v-if="translateResult" class="translateResult">
							{{ translateResult }}
						</span>
						<span v-if="videoTranscript" class="translateResult">
							{{ videoTranscript }}
						</span>
					</p>
				</div>
				<div class="row">
					<div v-for="(mastertag, k) in mastertagsStatus()" :key="k" class="dynvibePostMastertag">
						<span
							:class="mastertag.status"
							@mouseover="hoveredMastertagIndex = k"
							@mouseleave="hoveredMastertagIndex = -1"
							@click="findMatchingRules(k, mastertag.label)"
						>
							{{ mastertag.label }}</span
						>
						<span v-if="hoveredMastertagIndex === k" class="tooltiptext">{{ getMastertagType(mastertag.status) }}</span>
					</div>

					<div class="dynvibeStoryStats">
						<!--FIXME-->
						<span title="likes" alt="likes">
							<font-awesome-icon icon="thumbs-up" /> {{ formatToKM(post.post_likes) || "hidden" }}
						</span>
						<span
							v-if="post.post_media === 'instagram' || post.post_media === 'tiktok' || post.post_media === 'facebook'"
							title="comments"
							alt="comments"
						>
							<font-awesome-icon icon="comment" /> {{ formatToKM(post.post_comments) || "hidden" }}
						</span>
						<span
							v-if="post.post_media === 'twitter' || post.post_media === 'tiktok' || post.post_media === 'facebook'"
							title="shares"
							alt="shares"
						>
							<font-awesome-icon icon="retweet" /> {{ formatToKM(post.post_shares) || "unfetched" }}
						</span>
						<span v-if="post.post_media === 'youtube' || post.post_media === 'tiktok'" title="views" alt="views">
							<font-awesome-icon icon="eye" /> {{ formatToKM(post.post_views) || "hidden" }}
						</span>

						<span
							class="dynvibeStoryScore"
							title="Dynvibe Engagement rate is: ?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (? followers) Content: ? shares, ? favorites"
							alt="Dynvibe Engagement rate is: ?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (? followers) Content: ? shares, ? favorites"
						>
							{{ formatToKMPercent(post.post_engagement_rate, false, "engagement") || "0" }}
						</span>
					</div>
				</div>
				<div v-show="matchedRulesText != ''" class="row">
					<div class="col-lg-12">
						<span class="rulesMatchingResult" v-html="formatRulesText(matchedRulesText)"></span>
					</div>
				</div>
			</div>

			<div v-if="middle" class="col-lg-2 col-xs-12 pull-right dynvibeStoryInfo dynvibeSourceInfo">
				<span style="display: block">
					<a
						v-for="source in post.source"
						:key="source.media"
						class="dynvibeProfilesLinkPosts"
						:href="
							'https://www.' +
							source.media +
							'.com/' +
							(source.media === 'youtube'
								? 'channel/' + source.guid
								: source.media === 'tiktok'
								? '@' + source.username
								: source.username)
						"
						target="_blank"
						><font-awesome-icon :icon="['fab', source.media]" />
						<span>{{ formatLinkPosts(source) }}<br />({{ formatToKM(source.followers) }})</span>
					</a>
				</span>
			</div>
		</div>
	</div>
</template>

<script>
import storyMixin from "@/mixins/storyMixin.js";
// import mixin from "@/mixins/mixin.js";
import fiche from "@/components/posts/fiche.vue";
import { ACL } from "@/acl";
//import highlight from "@/components/highlight/highlight.vue";
//import confirm from "@components/globalVue/Confirm.vue";

export default {
	name: "Bigstory",
	components: {
		fiche: fiche,
		//highlight: highlight,
	},
	mixins: [storyMixin],
	inject: ["picture_host"],
	props: {
		checkedallstories: {
			type: Boolean,
			default: false,
		},
		checkable: {
			type: Boolean,
			default: true,
		},
	},
	emits: ["listcheckedmt", "buttonify", "editprofile"],

	data() {
		return {
			showModalRules: false,
			//mastertags:"",
			group1: "",
			group2: "",
			textToButton: [],
			rowSynonym1: [],
			rowSynonym2: [],
			showSyn1: false,
			showSyn2: false,
			listCheckedMt: [],
			story_is_checked: false,
			selectedMt: "",
			firstSyn1: "",
			firstSyn2: "",
			inputConfirm: false,
			confirmAdd: "",
			syn1Showed: false,
			syn2Showed: false,
			idprofile: "",
			// Is used for the tooltip to show the mastertag's type
			hoveredMastertagIndex: -1,
			matchedRulesText: "",
			currentMatchedRules: -1,
		};
	},
	computed: {
		ACL() {
			return ACL;
		},
		hasTranscript() {
			return typeof this.post.video_transcript !== "undefined" && this.post.video_transcript !== "";
		},
	},
	watch: {
		checkedallstories(newVal) {
			this.$emit("listcheckedmt", {
				event: newVal,
				mastertag: this.post.mastertags,
				storieschecked: this.post.post_id,
			});
		},
	},
	methods: {
		editProfile(idprofile) {
			this.idprofile = idprofile;
			this.$emit("editprofile", this.idprofile);
		},
		highlightpicture(post) {
			let st = false;
			if (typeof post.picture_text !== "undefined") {
				if (this.includetohighlight && post.picture_text.length > 0) {
					st = this.highlightWord(this.includetohighlight, "Include", post.picture_text);
				}
				if (this.searchtohighlight && post.picture_text.length > 0) {
					st = this.highlightWord(
						Array.isArray(this.searchtohighlight) ? this.searchtohighlight : [this.searchtohighlight],
						"Search",
						post.picture_text
					);
				}
			}
			return st;
		},
		mastertagsStatus() {
			if (typeof this.post.mastertags !== "undefined") {
				let results = [];
				let mastertags = [...this.post.mastertags];
				for (let i = 0; i < mastertags.length; i++) {
					let checkAlreadyAdded = results.filter((mastertag) => mastertag.label === mastertags[i].label);
					if (checkAlreadyAdded.length > 0) {
						continue;
					}

					let similar = mastertags.filter((mastertag) => mastertag.label === mastertags[i].label);
					let item = { ...mastertags[i] };
					let isOcr = similar.filter((item) => item.ocr).length > 0;
					let isTranscript = similar.filter((item) => item.transcript).length > 0;
					let isNeutral = similar.filter((item) => item.ocr === undefined && item.transcript === undefined).length > 0;
					if (isOcr && isTranscript && isNeutral) {
						item["status"] = "dynvibeNeutralOcrTranscriptText";
					} else if (isOcr && isTranscript) {
						item["status"] = "dynvibeNeutralOcrTranscript";
					} else if (isOcr && isNeutral) {
						item["status"] = "dynvibeNeutralOcrText";
					} else if (isOcr) {
						item["status"] = "dynvibeNeutralOcr";
					} else if (isTranscript && isNeutral) {
						item["status"] = "dynvibeNeutralTranscriptText";
					} else if (isTranscript) {
						item["status"] = "dynvibeNeutralTranscript";
					} else if (isNeutral) {
						item["status"] = "dynvibeNeutral";
					}

					results.push(item);
				}

				return results;
			} else {
				return this.post.mastertags;
			}
		},
		getMediaStyle(post) {
			let picture = post["post_url"].replace("https://www.instagram.com/p/", "").replace("/", "");

			if (post.post_media === "twitter") {
				picture = post.post_picture;
			}
			if (post.post_media === "instagram") {
				picture = this.picture_host + post.profile_id + "/source/" + post.post_url.split("/")[4] + ".jpg";
			}
			if (post.post_media === "youtube") {
				let post_url = post.post_url.split("/")[3];
				post_url = post_url.split("=")[1];
				picture =
					"url(" + this.picture_host + post.profile_id + "/source/youtube/" + post_url + ".jpg),url(" + post.post_picture + ");";
			}
			if (post.post_media === "tiktok") {
				picture =
					"url(" +
					this.picture_host +
					post.profile_id +
					"/source/tiktok/" +
					post.post_url.split("/")[5] +
					".jpg),url(" +
					post.post_picture +
					");";
			}
			if (post.post_media === "facebook") {
				picture =
					"url(" +
					this.picture_host +
					post.profile_id +
					"/source/facebook/" +
					post.post_url.split("/")[5] +
					".jpg),url(" +
					post.post_picture +
					");";
			}
			if (picture !== "") {
				if (post.post_media === "tiktok" || post.post_media === "youtube" || post.post_media === "facebook") {
					return "background-image:" + picture;
				} else {
					return "background-image:url(" + picture + ");";
				}
			}
			return "";
		},
		setImage(profile) {
			return this.picture_host + profile.profile_id + "/source/profile.jpg";
			// for (let i = 0; i < profile.source.length; i++) {
			// 	if (profile.source[i].media === "instagram") {
			// 		return this.picture_host + profile.profile_id + "/source/profile.jpg";
			// 	} else if (!profile.source[i].picture) {
			// 		continue;
			// 	}
			// 	let image = new Image();
			// 	image.src = profile.source[i].picture;

			// 	if (image.width > 0) {
			// 		return image.src;
			// 	}
			// }
			// return require("@/assets/img/default_profile_bigger.png");
		},
		formatLinkPosts(source) {
			return source.username;
		},
		getMastertagType(mastertagStatus) {
			if (mastertagStatus === "dynvibeNeutralOcrTranscriptText") {
				return "OCR + Transcript + Text";
			} else if (mastertagStatus === "dynvibeNeutralOcrTranscript") {
				return "OCR + Transcript";
			} else if (mastertagStatus === "dynvibeNeutralOcrText") {
				return "OCR + Text";
			} else if (mastertagStatus === "dynvibeNeutralOcr") {
				return "OCR";
			} else if (mastertagStatus === "dynvibeNeutralTranscriptText") {
				return "Text + Transcript";
			} else if (mastertagStatus === "dynvibeNeutralTranscript") {
				return "Transcript";
			} else if (mastertagStatus === "dynvibeNeutral") {
				return "Text";
			} else {
				return "";
			}
		},
		findMatchingRules(index, mastertag) {
			if (!this.ACL.isAdmin()) {
				return false;
			}
			if (this.currentMatchedRules === index) {
				this.currentMatchedRules = -1;
				this.matchedRulesText = "";
				return false;
			}
			this.currentMatchedRules = index;
			this.getData(
				"findRulesMatchMastertag",
				{
					mastertag: mastertag,
					id: this.post.post_id,
				},
				"",
				"indices"
			);
		},
		treatResponse(response) {
			this.matchedRulesText = response.data;
		},
		formatRulesText(text) {
			let formattedRules = "";
			console.log(text);
			if (text["post_content_matching_rules"] !== undefined && text["post_content_matching_rules"].length > 0) {
				formattedRules += "<b>Post content matching rules</b>: <br>";
				for (let i = 0; i < text["post_content_matching_rules"].length; i++) {
					let rules = text["post_content_matching_rules"][i];
					rules = rules.split("#SEPARATOR#");
					for (let j = 0; j < rules.length; j++) {
						formattedRules += "<br>" + rules[j];
					}
					formattedRules += "<br>";
				}
			}

			if (text["ocr_video_matching_rules"] !== undefined && text["ocr_video_matching_rules"].length > 0) {
				formattedRules += "<br><b>OCR video matching rules</b>: <br>";
				for (let i = 0; i < text["ocr_video_matching_rules"].length; i++) {
					let rules = text["ocr_video_matching_rules"][i];
					rules = rules.split("#SEPARATOR#");
					for (let j = 0; j < rules.length; j++) {
						formattedRules += "<br>" + rules[j];
					}
					formattedRules += "<br>";
				}
			}

			if (text["ocr_picture_matching_rules"] !== undefined && text["ocr_picture_matching_rules"].length > 0) {
				formattedRules += "<br><b>OCR picture matching rules</b>: <br>";
				for (let i = 0; i < text["ocr_picture_matching_rules"].length; i++) {
					let rules = text["ocr_picture_matching_rules"][i];
					rules = rules.split("#SEPARATOR#");
					for (let j = 0; j < rules.length; j++) {
						formattedRules += "<br>" + rules[j];
					}
					formattedRules += "<br>";
				}
			}

			if (text["video_transcript_matching_rules"] !== undefined && text["video_transcript_matching_rules"].length > 0) {
				formattedRules += "<br><b>Transcript matching rules</b>: <br>";
				for (let i = 0; i < text["video_transcript_matching_rules"].length; i++) {
					let rules = text["video_transcript_matching_rules"][i];
					rules = rules.split("#SEPARATOR#");
					for (let j = 0; j < rules.length; j++) {
						formattedRules += "<br>" + rules[j];
					}
					formattedRules += "<br>";
				}
			}

			return formattedRules;
		},
	},
};
</script>

<style scoped>
.postPicture_big {
	width: 200px;
	height: 200px;
	max-width: 100%;
	display: block;
	background-position: center;
	background-size: cover;
}

.translateResult {
	display: block;
	margin-bottom: 10px;
}

.translateResult {
	padding: 10px;
	border: 1px dashed #33d1cd;
	min-height: 60px;
}

.dynvibeStoryStats {
	display: flex;
	align-items: center;
	height: 35px;
	float: right;
	font-size: 18px;
	color: #9d9d9d;
}

.dynvibeStoryStats > span {
	margin-left: 15px;
}

.dynvibeStoryStats > span > span {
	margin-right: 3px;
}

.dynvibeStoryScore {
	font-size: 26px;
	min-width: 50px;
	text-align: right;
}

.ocr-text {
	background-color: yellow;
	font-size: 15px;
	text-align: center;
	display: block;
	color: slategray;
}

.ocr-image {
	border: 4px solid yellow;
}
.dynvibeSourceInfo a > svg {
	font-size: 20px;
	margin-right: 5px;
	margin-bottom: 11px;
}

.tooltiptext {
	position: absolute;
	min-width: 60px;
	max-width: 200px;
	line-height: 1.3em;
	background-color: #626262;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 11px 10px;
	font-size: 11px;
	z-index: 10000;
}

.rulesMatchingResult {
	padding: 10px;
	border: 1px dashed #33d1cd;
	min-height: 60px;
	word-break: break-all;
	width: 100%;
	font-family: "Montserrat", "Arial", "Helvetica", "sans-serif";
	color: #333;
	font-size: 13px;
}
</style>
