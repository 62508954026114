import { useMainStore } from "@/store";

export class ACL {
	static isAdmin() {
		return useMainStore().is_admin;
	}
	static isExternal() {
		return useMainStore().is_external;
	}
	static isClient() {
		return useMainStore().is_client;
	}
	static getState() {
		let state = "admin";
		if (this.isExternal() && !this.isClient()) {
			state = "external";
		} else if (this.isClient()) {
			state = "client";
		}
		return state;
	}
}
