<template>
	<nav v-if="(ACL.isAdmin() || ACL.isExternal()) && !ACL.isClient()" id="sidebar" class="navbar-fixed-left active">
		<div class="sidebar-header">
			<img class="img-responsive dynvibeLogo" src="@/assets/img/logo.png" alt="Dynvibe" />
			<img class="img-responsive dynvibeLogoLive" src="@/assets/img/logo_detect.png" alt="Dynvibe" />
		</div>
		<div class="menu-admin">
			<ul class="list-unstyled components">
				<li>
					<router-link :to="{ path: '/profiles' }">
						<div class="icon-menu-admin"><i class="icon-user"></i></div>
						Profiles
					</router-link>
				</li>
				<li>
					<router-link to="/author">
						<div class="icon-menu-admin"><i class="fa-solid fa-id-card"></i></div>
						Author
					</router-link>
				</li>
				<li>
					<router-link to="/posts">
						<div class="icon-menu-admin"><i class="icon-bookmark"></i></div>
						Posts
					</router-link>
				</li>
				<li v-if="ACL.isAdmin()">
					<router-link to="/rules">
						<div class="icon-menu-admin"><font-awesome-icon icon="ruler" /></div>
						Rules
					</router-link>
				</li>
				<li v-if="ACL.isAdmin()">
					<router-link to="/panels">
						<div class="icon-menu-admin"><font-awesome-icon icon="address-book" /></div>
						Panels
					</router-link>
				</li>
				<li v-if="ACL.isAdmin()">
					<router-link to="/categories">
						<div class="icon-menu-admin"><i class="icon-brands"></i></div>
						Categories
					</router-link>
				</li>
				<li v-if="ACL.isAdmin()">
					<router-link to="/segments">
						<div class="icon-menu-admin"><font-awesome-icon icon="tags" /></div>
						Segments
					</router-link>
				</li>
				<li>
					<router-link to="/newin">
						<div class="icon-menu-admin"><font-awesome-icon icon="exclamation-circle" /></div>
						New In
					</router-link>
				</li>
				<li>
					<router-link to="/trend-validation">
						<div class="icon-menu-admin"><font-awesome-icon icon="check-circle" /></div>
						Trend Validation
					</router-link>
				</li>
				<li v-if="ACL.isAdmin()">
					<router-link to="/data-management">
						<div class="icon-menu-admin"><font-awesome-icon :icon="['fas', 'search']" style="font-size: 1.7em" /></div>
						Data Management
					</router-link>
				</li>
				<li>
					<router-link to="/explore">
						<div class="icon-menu-admin"><font-awesome-icon icon="newspaper" /></div>
						Explore
					</router-link>
				</li>
				<li>
					<router-link to="/barometer">
						<div class="icon-menu-admin"><font-awesome-icon icon="weight" /></div>
						Barometer
					</router-link>
				</li>
				<li>
					<router-link to="/campaign">
						<div class="icon-menu-admin"><font-awesome-icon icon="ad" /></div>
						Campaign
					</router-link>
				</li>
				<li>
					<router-link to="/search-image">
						<div class="icon-menu-admin"><font-awesome-icon icon="images" /></div>
						Search Image
					</router-link>
				</li>
				<li v-if="ACL.isAdmin()">
					<router-link to="/trend-deactivation">
						<div class="icon-menu-admin"><font-awesome-icon icon="times-circle" /></div>
						Trend Deactivation
					</router-link>
				</li>
				<!-- <li v-if="ACL.isAdmin()">
					<router-link to="/profiles-validation">
						<div class="icon-menu-admin"><font-awesome-icon icon="file-contract" /></div>
						Profiles Validation
					</router-link>
				</li> -->
				<li v-if="ACL.isAdmin()">
					<router-link to="/report">
						<div class="icon-menu-admin"><font-awesome-icon icon="file-contract" /></div>
						Report
					</router-link>
				</li>
				<!-- <li v-if="ACL.isAdmin()">
					<router-link to="/authors-crawl">
						<div class="icon-menu-admin"><font-awesome-icon icon="spider" /></div>
						Authors Crawl
					</router-link>
				</li> -->
				<!-- <li v-if="ACL.isAdmin()">
					<router-link to="/animated">
						<div class="icon-menu-admin"><font-awesome-icon icon="chart-line" /></div>
						Animated chart
					</router-link>
				</li> -->
				<li v-if="ACL.isAdmin()">
					<router-link to="/check-data">
						<div class="icon-menu-admin"><font-awesome-icon icon="clipboard-check" style="font-size: 1.7em" /></div>
						Check data
					</router-link>
				</li>
				<li v-if="ACL.isAdmin()">
					<router-link to="/dashboards-list">
						<div class="icon-menu-admin"><font-awesome-icon :icon="['fas', 'list']" style="font-size: 1.7em" /></div>
						Dashboards list
					</router-link>
				</li>
				<li>
					<router-link to="/account-management">
						<div class="icon-menu-admin"><font-awesome-icon :icon="['fas', 'user-plus']" style="font-size: 1.7em" /></div>
						Account management
					</router-link>
				</li>
				<li>
					<router-link to="/check-sources">
						<div class="icon-menu-admin"><font-awesome-icon :icon="['fas', 'tasks']" style="font-size: 1.8em" /></div>
						Check sources
					</router-link>
				</li>
				<li>
					<a style="color: darkred; cursor: pointer" @click="logout()">
						<div class="icon-menu-admin"><font-awesome-icon icon="door-open" /></div>
						<span>Logout</span>
					</a>
				</li>
				<!--
				<li>
					<router-link to="/exit">
						<font-awesome-icon icon="door-open" />
						Exit Admin
					</router-link>
				</li>
				-->
			</ul>
		</div>
	</nav>
</template>

<script>
import { ACL } from "@/acl";

export default {
	computed: {
		ACL() {
			return ACL;
		},
	},
	methods: {
		logout() {
			this.$axios.post(this.$backendUrl + "logout").then(() => {
				this.$store.dispatch("setJwt", "");
				this.$store.dispatch("setUsername", "");
				this.$store.dispatch("setIsAdmin", false);
				this.$store.dispatch("setIsExternal", false);
				this.$store.dispatch("setIsClient", false);

				window.location.href = "/login";
			});
		},
	},
};
</script>

<style scoped>
.router-link-active {
	color: #33d1cd;
}

.menu-admin {
	align-items: center;
	height: calc(100vh - 100px);
	overflow: auto;
}
#sidebar:not(.active) .menu-admin div.icon-menu-admin {
	display: inline-block;
	width: 20px;
}
#sidebar:not(.active) ul li a {
	font-size: 1em;
}
#sidebar:not(.active) svg {
	width: 14px;
}
.menu-admin > li > a > svg {
	margin-right: 7px;
	margin-left: 0;
}
#sidebar.active ul li a {
	margin-right: 12;
	margin-left: 0;
}
#sidebar ul li a i,
#sidebar ul li a .svg-inline--fa.fa-w-18 {
	margin: 0;
}
#sidebar.active ul li a .svg-inline--fa.fa-w-18 {
	margin-left: 33px !important;
}

#sidebar.active ul li a {
	padding: 15px;
}
</style>
