<template>
	<div>
		<div v-if="title">
			<div :class="dynvibeBox === true ? 'dynvibeBox' : ''">
				<div class="col-lg-12 dynvibeTitleBox">
					<div
						v-if="editable"
						id="filterListWord"
						class="buttonWordCloud iconeEditWordCloud"
						title="edit"
						@click="displayDataTable"
					>
						<img
							alt="edit"
							src="https://upload.wikimedia.org/wikipedia/commons/6/64/Edit_icon_%28the_Noun_Project_30184%29.svg"
							style="width: 24px !important; height: 24px !important"
						/>
					</div>
					<!-- <div v-if="displayFilterLanguage" class="buttonWordCloud iconeEditWordCloud">
						<select v-model="selectedlanguage" class="selectLanguage">
							<option value="">Remove Stop Words of :</option>
							<option value="fr_en">fr,en</option>
							<option v-for="(item, index) in languages" :key="index" :value="item.key">
								{{ item.key }}
							</option>
						</select>
					</div> -->
					<gselect
						v-if="displayFilterLanguage"
						:wrapper-classes="['buttonWordCloud', 'iconeEditWordCloud']"
						:options="languages"
						:placeholder="'Remove Stop Words of :'"
						:value="selectedlanguage"
						:inside-classes="['selectLanguage']"
						:reduce="(lang) => lang.key"
						:get-option-label="(lang) => lang.key"
						@update="(e) => (selectedlanguage = e)"
						><option value="fr_en">fr,en</option></gselect
					>
					<h2>{{ title }}</h2>
				</div>
				<div class="col-lg-12">
					<div v-if="loading" class="dynvibeLoading"></div>
					<div v-if="!loading && noData" class="noData">
						<p>No Data to display</p>
					</div>
					<div v-if="translated" ref="vuetagcloud" class="vue-tag-cloud">
						<span
							v-for="(word, index) in wordVisibleArray"
							:key="index"
							:ref="word.text"
							:class="word.class"
							@click="wordClicked"
							>{{ word.text }} <br />
							<span v-if="shouldTranslateWord(word)"> [{{ word.translatedText }}] </span>
						</span>
					</div>
					<div v-else ref="vuetagcloud" class="vue-tag-cloud">
						<span
							v-for="(word, index) in wordVisibleArray"
							:key="index"
							:ref="word.text"
							:class="word.class"
							@click="wordClicked"
							>{{ word.text }} <br />
						</span>
					</div>
				</div>
				<div v-if="translatable && shouldTranslateWordcloud" class="col-lg-12 translate-switch">
					<label class="switch">
						<input v-model="translated" type="checkbox" name="translated" />
						<span class="slider round"></span>
					</label>
					<p>{{ translated ? "HIDE TRANSLATION" : "SHOW TRANSLATION" }}</p>
				</div>
			</div>
		</div>
		<div v-else>
			<div :class="dynvibeBox === true ? dynvibeBox : ''">
				<div class="col-lg-12">
					<div v-if="loading" class="dynvibeLoading"></div>
					<div v-if="!loading && noData" class="noData">
						<p>No Data to display</p>
					</div>
					<div ref="vuetagcloud" class="vue-tag-cloud">
						<span v-for="word in wordVisibleArray" :key="word.text" :ref="word.text" :class="word.class" @click="wordClicked"
							>{{ word.text }}
						</span>
					</div>
					<!-- <dynvibegraphdetails v-show="showGraphDetails" ref="graphDetails" :axios="axios"> </dynvibegraphdetails> -->
				</div>
			</div>
		</div>

		<div v-if="showDatatable">
			<div class="modal-mask">
				<div class="modal-wrapper">
					<div class="modal-dialog modal-lg">
						<div class="modal-content">
							<div class="modal-header">
								<div class="col-lg-12 dynvibeTitleBox">
									<h5 class="modal-title">
										<span class="dynvibeModalTitle">Edit wordcloud</span>
										<button type="button" class="close" @click="refreshWordcloud">
											<span aria-hidden="true">
												<i class="icon-x"></i>
											</span>
										</button>
									</h5>
								</div>
							</div>
							<div class="modal-body">
								<div v-if="datatableLoading" class="dynvibeLoading"></div>
								<datatable
									v-else
									ref="datatable"
									:data="wordArrayFormatted"
									:editable="false"
									:headers="headers"
									:handler="true"
									:searchable="true"
									:rulespage="false"
									:table-size="3"
								>
									<template #cell="{ value: value }">
										{{ value }}
									</template>
									<template #handle="{ indexrow: indexRow }">
										<span v-if="wordArray[indexRow].display" @click="toggleDisplay(indexRow)"> ✔ </span>
										<span v-else @click="toggleDisplay(indexRow)"> ✘ </span>
									</template>
								</datatable>
								<div class="dynvibeRow">
									<div class="col-md-12">
										<div class="text-center">
											<button class="dynvibeButton datatableButton" @click="refreshWordcloud">Refresh</button>
											<button v-if="displayLoadMore" class="dynvibeButton datatableButton" @click="loadMore">
												Load more
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<!--suppress JSJQueryEfficiency -->
<script>
// import dynvibegraphdetails from "@/components/vueCharts/GraphDetails.vue";
import datatable from "@/components/globalVue/datatable.vue";
import { useFilterStore } from "@/store";

export default {
	name: "Dynvibewordcloud",
	components: {
		// dynvibegraphdetails: dynvibegraphdetails,
		datatable: datatable,
	},
	props: {
		axios: {
			type: String,
			default: "",
		},
		title: {
			type: String,
			default: null,
		},
		data: {
			type: Array,
			default: () => [],
		},
		width: {
			type: Number,
			default: 800,
		},
		height: {
			type: Number,
			default: 400,
		},
		clickable: {
			type: Boolean,
			default: true,
		},
		urlToGet: {
			type: String,
			default: "wordcloud",
		},
		editable: {
			type: Boolean,
			default: true,
		},
		event: {
			type: Boolean,
			default: false,
		},
		eventRelative: {
			type: Boolean,
			default: false,
		},
		profileId: {
			// Profiles validation in middle
			type: Number,
			default: 0,
		},
		dynvibeBox: {
			// Profiles validation in middle
			type: Boolean,
			default: true,
		},
		mainkeyword: {
			type: String,
			default: "",
		},
		level: {
			type: String,
			default: "",
		},
		date: {
			type: Object,
			default: () => {
				return {
					gte: "",
					lte: "",
				};
			},
		},
		country: {
			type: String,
			default: "",
		},
		source: {
			type: String,
			default: "",
		},
		displayFilterLanguage: {
			type: Boolean,
			default: false,
		},
		filters: {
			type: Object,
			default: function () {
				return {};
			},
		},
		entityType: {
			type: String,
			default: "Posts",
		},
		translatable: {
			type: Boolean,
			default: true,
		},
	},
	emits: ["worcloudloaded", "wordcloudclicked", "reloadoutergraphdetail"],
	data() {
		return {
			loading: true,
			noData: false,
			wordArray: this.data,
			wordVisibleArray: this.data,
			cloud_namespace: Math.floor(Math.random() * 1000000).toString(36),
			options: {
				width: this.width,
				height: this.height,
				center: {
					x: this.width / 2.0,
					y: this.height / 2.0,
				},
				removeOverflowing: true,
			},
			already_placed_words: [],
			showGraphDetails: false,
			showDatatable: false,
			url: this.urlToGet,
			mastertagEventWC: null,
			togglebutton: false,
			margin: 30,
			headers: ["Word", "Translation", "Recurrence", "Display"],
			languages: [],
			selectedlanguage: "",
			size: 50,
			from: 0,
			modeAppend: false,
			displayLoadMore: true,
			datatableLoading: false,
			translated: false,
			shouldTranslateWordcloud: true,
		};
	},
	computed: {
		wordArrayFormatted() {
			let out = [];
			for (let i = 0; i < this.wordArray.length; i++) {
				out.push([this.wordArray[i].text, this.wordArray[i].translatedText, this.wordArray[i].weight]);
			}
			return out;
		},
	},
	watch: {
		filters: {
			handler() {
				if (Object.keys(this.filters).length > 0) {
					this.loadComponent(this.filters);
				}
			},
			deep: true,
		},
		"$store.parameters": {
			deep: true,
			handler() {
				if (Object.keys(this.filters).length === 0) {
					this.loadComponent(this.$store.parameters);
				}
			},
		},
		selectedlanguage(/*newVal, oldVal*/) {
			if (this.loading === false /*&& (this.explorePage == true || this.incomingModal) */) {
				this.loadComponent(this.$parent.$parent.filters);
			}
		},
		translated() {
			this.toggleTranslated();
		},
	},
	mounted() {
		useFilterStore().$subscribe(() => {
			this.loadComponent(this.$store.parameters);
		});
	},
	methods: {
		shouldTranslateWord(word) {
			// Verify if original word contains #
			let text = word.text;
			if (word.text[0] === "#") {
				text = word.text.substring(1);
			}

			if (word.translatedText === undefined) {
				return false;
			}

			return text.toLowerCase() !== word.translatedText.toLowerCase();
		},
		// set parameters
		async loadComponent(parameters, tagclass = "", word = null, mastertagEventWC = null) {
			if (Object.keys(this.filters).length !== 0) {
				parameters = this.filters;
			}
			if (!this.modeAppend) {
				this.wordArray = [];
				this.wordVisibleArray = [];
			}
			// parameters.type = this.entityType;
			let params = {
				parameters: {
					...parameters,
					date: useFilterStore().date,
					...useFilterStore().loupeFilters,
				},
				data: {
					function: this.axios,
					from: this.from,
					size: this.size,
					entity: this.entityType,
				},
			};

			delete params.parameters.language;

			if (this.eventRelative) {
				// If we just clicked on "back to previous graph", graphDetails gives us the previously active word
				this.mastertagEventWC = mastertagEventWC;
				params.tagclass = tagclass;
				params.parameters.mastertagEvent = word;
				params.parameters.tagclassWC = tagclass;
			}
			this.loading = true;

			// Profiles validation in middle
			if (this.profileId) {
				params.data.profileId = this.profileId;
			}
			// modal create mastertag / trend
			if (this.mainkeyword) {
				params.data.mainkeyword = this.mainkeyword;
				params.data.level = this.level;
			} else if (this.level) {
				params.data.level = this.level;
			}
			if (this.selectedlanguage) {
				params.data.lang = this.selectedlanguage;
			}

			// call getData(url) from mixin
			if (this.date.gte !== "") {
				params.parameters.date = this.date;
			}
			if (this.country !== "") {
				params.parameters.country = [this.country];
			}
			if (this.source !== "") {
				params.parameters.sources = [this.source];
			}
			if (params.parameters && Object.keys(params.parameters).length === 0) {
				params.parameters = JSON.parse(
					JSON.stringify({
						...this.$store.parameters,
						date: useFilterStore().date,
						...useFilterStore().loupeFilters,
					})
				);
			}
			if (!params.parameters.panel && useFilterStore().panel !== "") {
				params.parameters.panel = useFilterStore().panel;
			}
			if (!params.parameters.segment && useFilterStore().segment !== "") {
				params.parameters.segment = useFilterStore().segment;
			}

			if (this.axios !== "profilesValidation" || (this.axios === "profilesValidation" && this.profileId !== 0)) {
				this.getData(this.url, params, "", "indices");
				if (!this.modeAppend) {
					this.updateWordcloud();
				}
			}
		},
		treatResponse(response) {
			this.loading = false;
			//this.translated = false;
			if (!response.data) {
				this.noData = true;
			} else {
				this.noData = false;
				if (this.displayFilterLanguage) {
					if (!this.modeAppend) {
						this.wordArray = response.data.results;
						this.wordArray.forEach((a) => (a.display = true));
					} else {
						let appendData = response.data.results;
						appendData.forEach((a) => (a.display = true));
						if (appendData.length === 0) {
							this.displayLoadMore = false;
						}
						this.wordArray = this.wordArray.concat(appendData);
					}

					this.languages = response.data.languages;
					/*if (this.selectedlanguage == "" && this.languages.length > 0) {
						this.selectedlanguage = this.languages[0].key;
					}*/
				} else {
					if (!this.modeAppend) {
						if (response.data.results) {
							this.wordArray = response.data.results;
							this.languages = response.data.languages;
						} else {
							this.wordArray = response.data;
						}
						this.wordArray.forEach((a) => (a.display = true));
					} else {
						let appendData = response.data;
						appendData.forEach((a) => (a.display = true));
						if (appendData.length === 0) {
							this.displayLoadMore = false;
						}
						this.wordArray = this.wordArray.concat(appendData);
					}
				}

				// FOR SOME REASON IT DOES NOT WORK this.wordVisibleArray = this.wordArray.filter((a) => a.display === true);
				this.wordVisibleArray = [];
				for (let i = 0; i < this.wordArray.length; i++) {
					if (this.wordArray[i].display) {
						this.wordVisibleArray.push(this.wordArray[i]);
					}
				}
				this.datatableLoading = false;

				if (this.wordArray.length === 0) {
					this.noData = true;
				}

				if (this.modeAppend) {
					this.modeAppend = false;
					return;
				}
				this.constituteBuckets();

				// need to wait for next tick before call updateWordcloud
				// wordArray is set so it's updated in the v-for

				this.$nextTick(() => {
					this.updateWordcloud();
					// If we just clicked on "back to previous graph", graphDetails gave us the previously active word and we recolor it
					if (this.mastertagEventWC) {
						this.$refs[this.mastertagEventWC][0].classList.add("active");
					}
				});
				if (this.translatable === false) {
					this.shouldTranslateWordcloud = false;
				} else {
					const fromLanguage = this.languages.length > 0 ? this.languages[0].key : "";
					this.translateWordcloud(this.wordArray, fromLanguage, this.axios);
				}
				this.$emit("worcloudloaded");
			}
		},
		loadMore() {
			this.datatableLoading = true;
			this.modeAppend = true;
			this.from += this.size;
			this.loadComponent(this.$store.parameters);
		},
		changeUrl(newUrl) {
			this.url = newUrl;
			this.loadComponent(this.$store.parameters);
		},
		constituteBuckets() {
			let totalWeight = this.wordVisibleArray.reduce((x, y) => {
				return x + parseInt(y.weight);
			}, 0);
			let mean = totalWeight / this.wordVisibleArray.length;
			/*let variance = 0;

			this.wordVisibleArray.forEach((word) => {
				variance += Math.pow(Math.abs(word.weight - mean), 2);
			});*/

			let ecartType = 0;

			this.wordVisibleArray.forEach((word, index) => {
				if (index > 0) {
					ecartType += parseInt(this.wordVisibleArray[index - 1].weight) - parseInt(word.weight);
				}
			});

			ecartType = ecartType / (this.wordVisibleArray.length - 1);

			let buckets = [];
			let bucketIndex = 0;
			this.wordVisibleArray.forEach((word, index) => {
				if (index === 0) {
					buckets[bucketIndex] = [word];
				} else if (this.wordVisibleArray[index - 1].weight - word.weight <= ecartType) {
					buckets[bucketIndex].push(word);
				} else {
					bucketIndex += 1;
					buckets[bucketIndex] = [word];
				}
			});

			buckets.forEach((bucket) => {
				let totalBucket = bucket.reduce((x, y) => {
					return x + parseInt(y.weight);
				}, 0);
				let meanBucket = totalBucket / bucket.length;
				let category;
				if (meanBucket >= mean) {
					category = [1, 1.25, 1.5, 5, 10].reduce((a, b) => {
						return Math.abs(b - meanBucket / mean) < Math.abs(a - meanBucket / mean) ? b : a;
					});
				} else {
					category = [1, 1.25, 1.5, 5, 10].reduce((a, b) => {
						return Math.abs(b - mean / meanBucket) < Math.abs(a - mean / meanBucket) ? b : a;
					});
					category = -category;
				}
				if (category > 10) {
					category = 10;
				} else if (category < -10) {
					category = -10;
				}

				let mappingCategoryClass = {
					10: 10,
					5: 9,
					1.5: 8,
					1.25: 7,
					1: 6,
					"-1": 5,
					"-1.25": 4,
					"-1.5": 3,
					"-5": 2,
					"-10": 1,
				};
				bucket.forEach((word) => {
					word.class = "w" + mappingCategoryClass[category] + " " + word.html.class;
				});
			});
		},
		updateWordcloud() {
			// get the updated width and height from the vuetagcloud ref
			if (this.$refs.vuetagcloud && this.$refs.vuetagcloud.parentNode.clientWidth !== 0) {
				this.options.width = this.$refs.vuetagcloud.parentNode.clientWidth - this.margin;
			}

			if (this.$refs.vuetagcloud && this.$refs.vuetagcloud.parentNode.clientHeight !== 0 && !this.options.height) {
				this.options.height = this.$refs.vuetagcloud.parentNode.clientHeight - this.margin;
			}

			// compute center
			this.options.center.x = this.options.width / 2.0;
			this.options.center.y = this.options.height / 2.0;

			// remove all items from the array
			this.already_placed_words = [];
			if (this.wordVisibleArray) {
				this.wordVisibleArray.forEach(
					function (word, index) {
						// for each word we update the left and top position
						this.updateWordPosition(index, word);
						this.already_placed_words.push(word);
					}.bind(this)
				);
			}
			//this.translated = false;
		},
		// event triggered when click on a word
		wordClicked(event) {
			// If it is a wordcloud in a graphDetails
			let word = event.target.innerText || event.target.textContent;

			if (this.mainkeyword !== "") {
				this.$emit("wordcloudclicked", word);
			} else if (this.eventRelative) {
				// TODO : still used ? Seems to be live related
				if (this.$refs[word][0].className.includes("active") || this.$refs[word][0].className.includes("colorActive")) {
					this.$refs[word][0].classList.remove("active");
					this.$emit("reloadoutergraphdetail", null);
				} else {
					let spans = document.getElementsByTagName("span");
					for (let i = 0; i < spans.length; i++) {
						spans[i].classList.remove("active", "colorActive");
					}
					//$("span").removeClass("active colorActive");
					this.$refs[word][0].classList.add("active");
					this.$emit("reloadoutergraphdetail", word);
				}
			} else if (this.clickable) {
				if (word.length > 0) {
					this.$refs.graphDetails.getDataWordCloud(word, this.event, true, true);
				}
				this.showGraphDetails = true;
			}
		},

		// compute left and top position and change the span position of the word
		updateWordPosition(index, word) {
			let text = word.text;
			if (!this.$refs[text]) {
				return;
			}

			if (index > 49) {
				// limit wordcloud to 50 elements
				this.$refs[text].style.display = "none";
				return;
			}

			let width = this.$refs[text].offsetWidth;
			let height = this.$refs[text].offsetHeight;
			let left = this.options.center.x - width / 2.0;
			let top = this.options.center.y - height / 2.0;
			let radius = 0.0;
			let angle = 6.28 * Math.random();

			let position = "absolute";

			let step = 2.0;
			let aspect_ratio = this.options.width / this.options.height;

			this.$refs[text].style.left = left + "px";
			this.$refs[text].style.top = top + "px";
			this.$refs[text].style.position = position;
			let indexAttempt = 0;
			while (this.hitTest(word, this.already_placed_words)) {
				radius += step;
				angle += (index % 2 === 0 ? 1 : -1) * step;

				left = this.options.center.x - width / 2.0 + radius * Math.cos(angle) * aspect_ratio;
				top = this.options.center.y + radius * Math.sin(angle) - height / 2.0;

				this.$refs[text].style.left = left + "px";
				this.$refs[text].style.top = top + "px";
				this.$refs[text].style.position = position;
				indexAttempt++;

				if (indexAttempt > 5000) {
					// prevent infinite loop
					this.$refs[text].style.display = "none";
					break;
				}
			}

			if (
				this.options.removeOverflowing &&
				(left < 0 || top < 0 || left + width > this.options.width || top + height > this.options.height)
			) {
				this.$refs[text].style.display = "none";
			}
		},
		// check if the two elements are not in collision
		hitTest(elem, other_elems) {
			// Pairwise overlap detection
			// this.$refs[a.text].offsetHeight
			let overlapping = (a, b) => {
				a = this.$refs[a.text];
				b = this.$refs[b.text];

				if (Math.abs(2.0 * a.offsetLeft + a.offsetWidth - 2.0 * b.offsetLeft - b.offsetWidth) < a.offsetWidth + b.offsetWidth) {
					if (
						Math.abs(2.0 * a.offsetTop + a.offsetHeight - 2.0 * b.offsetTop - b.offsetHeight) <
						a.offsetHeight + b.offsetHeight
					) {
						return true;
					}
				}
				return false;
			};
			let i = 0;

			// Check elements for overlap one by one, stop and return true as soon as an overlap is found
			for (i = 0; i < other_elems.length; i++) {
				if (elem)
					if (overlapping(elem, other_elems[i])) {
						return true;
					}
			}
			return false;
		},
		toggleDisplay(indexRow) {
			this.wordArray[indexRow].display = !this.wordArray[indexRow].display;
			this.wordArray = this.wordArray.slice(); // don't know why I have to copy the array,
			// to see changes =>
			//answer to see changes the reference of the object mst be changed to be observed,
			// changing inner object elements wont result in changing its reference therefore, the watcher will not detect a change

			// FOR SOME REASON IT DOES NOT WORK this.wordVisibleArray = this.wordArray.filter((a) => a.display === true);
			this.wordVisibleArray = [];
			for (let i = 0; i < this.wordArray.length; i++) {
				if (this.wordArray[i].display) {
					this.wordVisibleArray.push(this.wordArray[i]);
				}
			}
			this.constituteBuckets();
		},
		refreshWordcloud() {
			this.constituteBuckets();
			// need to wait for next tick before call updateWordcloud
			// wordArray is set so it's updated in the v-for
			this.$nextTick(() => {
				this.updateWordcloud();
			});
			this.showDatatable = false;
		},
		toggleTranslated() {
			this.refreshWordcloud();
		},
		displayDataTable() {
			this.showDatatable = true;
		},
		treatPostResponse(response) {
			if (typeof response.data === "object") {
				this.wordArray = response.data.data.textTranslate;
				// FOR SOME REASON IT DOES NOT WORK this.wordVisibleArray = this.wordArray.filter((a) => a.display === true);
				this.wordVisibleArray = [];
				for (let i = 0; i < this.wordArray.length; i++) {
					if (this.wordArray[i].display) {
						this.wordVisibleArray.push(this.wordArray[i]);
					}
				}
				this.refreshWordcloud();
			}
		},
	},
};
</script>

<style scoped>
:deep(.selectLanguage) {
	/* align-items: center; */
	/* height: 20px; */
	max-width: 200px;
	min-width: 100px;
	padding: 0 4px 0 7px;
	margin: 0 10px 5px 0;
	border-radius: 12px;
	font-size: 13px;
	font-weight: 700;
	border: 1px solid #cacaca;
	color: #8e8e8e;
	outline: 0;
}
.modal-active {
	display: block;
}

.modal-mask {
	position: fixed;
	z-index: 9998;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: table;
	transition: opacity 0.3s ease;
}

.modal-wrapper {
	display: table-cell;
	vertical-align: middle;
}

.dynvibeTitleBox {
	padding-bottom: 10px;
	margin-bottom: 0;
}

.dynvibeModalTitle {
	font-size: 16px;
	font-weight: 700;
	text-transform: uppercase;
}

.buttonWordCloud {
	cursor: pointer;
}

.iconeEditWordCloud {
	float: right;
}

.getMoreWordsWordCloud {
	display: block;
	font-size: 14px;
	font-weight: 600;
	padding: 10px 15px;
	border: none;
	text-transform: uppercase;
	outline: 0;
	margin: auto auto 1em;
}

div#wordcloudEventsRelative span.active {
	color: #fd0101;
	font-weight: bold;
}

.refreshWordCloud {
	display: block;
	margin: auto;
	font-size: 14px;
	font-weight: 600;
	padding: 10px 15px;
	border: none;
	text-transform: uppercase;
	outline: 0;
}

.vue-tag-cloud {
	font-family: "Intro", "Arial", "Helvetica", "sans-serif";
	font-size: 10px;
	line-height: normal;
	width: auto;
	display: inline-block;
	position: relative;
	min-height: 390px;
	height: auto;
	color: #09f;
}

.vue-tag-cloud a {
	font-size: inherit;
	text-decoration: none;
	color: inherit;
}

.vue-tag-cloud a:hover {
	color: #0df;
}

.vue-tag-cloud span {
	padding: 0;
	cursor: pointer;
	white-space: nowrap;
}

.vue-tag-cloud span.active.color1,
.vue-tag-cloud span.active.color2,
.vue-tag-cloud span.colorActive,
.vue-tag-cloud span.colorActive {
	color: #fd0101 !important;
	font-weight: bold;
}

.vue-tag-cloud span.w10 {
	font-size: 450%;
}

.vue-tag-cloud span.w9 {
	font-size: 380%;
}

.vue-tag-cloud span.w8 {
	font-size: 322%;
}

.vue-tag-cloud span.w7 {
	font-size: 273%;
}

.vue-tag-cloud span.w6 {
	font-size: 231%;
}

.vue-tag-cloud span.w5 {
	font-size: 195%;
}

.vue-tag-cloud span.w4 {
	font-size: 165%;
}

.vue-tag-cloud span.w3 {
	font-size: 140%;
}

.vue-tag-cloud span.w2 {
	font-size: 118%;
}

.vue-tag-cloud span.w1 {
	font-size: 100%;
}

.vue-tag-cloud span.w10.color1 {
	color: rgba(51, 209, 205, 1);
}

.vue-tag-cloud span.w9.color1 {
	color: rgba(51, 209, 205, 1);
}

.vue-tag-cloud span.w8.color1 {
	color: rgba(51, 209, 205, 0.8);
}

.vue-tag-cloud span.w7.color1 {
	color: rgba(51, 209, 205, 0.8);
}

.vue-tag-cloud span.w6.color1 {
	color: rgba(51, 209, 205, 0.6);
}

.vue-tag-cloud span.w5.color1 {
	color: rgba(51, 209, 205, 0.6);
}

.vue-tag-cloud span.w4.color1 {
	color: rgba(51, 209, 205, 0.6);
}

.vue-tag-cloud span.w3.color1 {
	color: rgba(128, 128, 128, 1);
}

.vue-tag-cloud span.w2.color1 {
	color: rgba(128, 128, 128, 0.8);
}

.vue-tag-cloud span.w1.color1 {
	color: rgba(128, 128, 128, 0.8);
}

.vue-tag-cloud span.w10.color2 {
	color: rgba(73, 27, 183, 1);
}

.vue-tag-cloud span.w9.color2 {
	color: rgba(73, 27, 183, 1);
}

.vue-tag-cloud span.w8.color2 {
	color: rgba(73, 27, 183, 0.8);
}

.vue-tag-cloud span.w7.color2 {
	color: rgba(73, 27, 183, 0.8);
}

.vue-tag-cloud span.w6.color2 {
	color: rgba(73, 27, 183, 0.6);
}

.vue-tag-cloud span.w5.color2 {
	color: rgba(73, 27, 183, 0.6);
}

.vue-tag-cloud span.w4.color2 {
	color: rgba(73, 27, 183, 0.6);
}

.vue-tag-cloud span.w3.color2 {
	color: rgba(128, 128, 128, 1);
}

.vue-tag-cloud span.w2.color2 {
	color: rgba(128, 128, 128, 0.8);
}

.vue-tag-cloud span.w1.color2 {
	color: rgba(128, 128, 128, 0.8);
}

.datatableButton {
	margin-left: 5px;
	margin-right: 5px;
	margin-bottom: 15px;
}

/* The switch - the box around the slider */
.translate-switch {
	display: flex;
	margin-top: 20px;
}

.translate-switch .switch {
	position: relative;
	display: inline-block;
	width: 30px;
	height: 17px;
	margin-top: 3px;
}

/* Hide default HTML checkbox */

.translate-switch .switch input {
	opacity: 0;
	width: 0;
	height: 0;
}

/* The slider */

.translate-switch .slider {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.translate-switch .slider:before {
	position: absolute;
	content: "";
	height: 14px;
	width: 14px;
	left: 2px;
	bottom: 1.4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.translate-switch input:checked + .slider {
	background-color: #33d1cd;
}

.translate-switch input:focus + .slider {
	box-shadow: 0 0 1px #33d1cd;
}

.translate-switch input:checked + .slider:before {
	-webkit-transform: translateX(12px);
	-ms-transform: translateX(12px);
	transform: translateX(12px);
}

/* Rounded sliders */

.translate-switch .slider.round {
	border-radius: 34px;
}

.translate-switch .slider.round:before {
	border-radius: 50%;
}

.translate-switch p {
	margin-left: 5px;
}
</style>
