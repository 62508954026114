<template>
	<div class="dataTables_wrapper no-footer">
		<div class="dataTables_length table_length">
			<label
				>Show
				<select v-model="pageSize" name="dynvibeTableRules_length" aria-controls="dynvibeTableRules" class="">
					<option value="5">5</option>
					<option value="10">10</option>
					<option value="25">25</option>
					<option value="50">50</option>
					<option value="100">100</option>
				</select>
				entries
			</label>
		</div>
		<div v-if="searchable" class="dataTables_filter">
			<label
				>Search:
				<input v-model="searchQuery" type="search" class="table_search" placeholder="" aria-controls="dynvibeTableRules" />
			</label>
		</div>
		<table class="table table-striped table-bordered dataTable no-footer" role="grid" aria-describedby="dynvibeTableRules_info">
			<thead>
				<tr role="row">
					<th
						v-for="(item, index) in headers"
						:key="index"
						scope="col"
						class="sorting"
						tabindex="0"
						aria-controls="dynvibeTableRules"
						rowspan="1"
						colspan="1"
						:style="getColumnSize(item)"
					>
						{{ item }}
					</th>
				</tr>
			</thead>
			<tbody :key="key">
				<tr v-for="(row, indexRow) in displayedRules" :key="indexRow + generateUniqueID()" :style="getRowColor(row)">
					<td
						v-for="(value, indexCol) in row[0].slice(0, tableSize)"
						:key="indexRow + '-' + indexCol"
						:contenteditable="editable && row[0][4] != 'Translation'"
						@blur="chooseEditCellFunction(indexRow, indexCol, $event)"
					>
						<slot
							name="cell"
							:value="value"
							:col="headers[indexCol]"
							:indexrow="indexRow + (currentPage - 1) * pageSize"
							:indexcol="indexCol"
						/>
					</td>
					<!--<td :contenteditable="editable" @blur="editCell(indexRow,2,$event)" v-if="row[0].length > 2 && row[0][2] !== 'none'">{{ row[0][2] }}</td>
				<td :contenteditable="editable" @blur="editCell(indexRow,2,$event)" v-else-if="row[0].length > 2"></td>-->
					<td v-if="rulespage">
						<select v-model="row[0][2]" class="dynvibeModalAdvancedSearchInput dynvibeListMastertags">
							<option value="none"></option>
							<option v-for="lang in languageslist" :key="lang.id" :value="lang.id">
								{{ lang.value }}
							</option>
						</select>
					</td>
					<td v-if="dataManagementPage">
						<div v-if="displaySelectLanguage && row[0][4] != 'Translation'">
							<select v-model="row[0][2]" class="dynvibeModalAdvancedSearchInput dynvibeListMastertags">
								<option value="none"></option>
								<option v-for="lang in languageslist" :key="lang.id" :value="lang.id">
									{{ lang.value }}
								</option>
							</select>
						</div>
						<div v-else>
							<div>
								{{ getLanguage(row[0][2]) }}
							</div>
						</div>
					</td>
					<td v-if="dataManagementPage && row[0][3] != undefined">
						<span>{{ row[0][3] }}</span>
					</td>
					<td v-if="displayHandleSolt">
						<slot name="handle" :indexrow="row[1]" :namerow="row[0][4]" :page="currentPage" :size="pageSize" />
					</td>
				</tr>
			</tbody>
		</table>
		<div id="dynvibeTableRules_info" class="dataTables_info" role="status" aria-live="polite">
			Showings {{ showingStart }} to {{ showingEnd }} of {{ tableFilteredData.length }} entries
		</div>
		<div class="dataTables_paginate paging_simple_numbers">
			<a
				:class="'paginate_button previous ' + (currentPage > 1 ? '' : 'disabled')"
				aria-controls="dynvibeTableRules"
				@click="currentPage = 1"
				>&lt;&lt;</a
			>
			<a
				v-if="currentPage > 1"
				:class="'paginate_button previous ' + (currentPage > 1 ? '' : 'disabled')"
				aria-controls="dynvibeTableRules"
				data-dt-idx="0"
				tabindex="-1"
				@click="currentPage--"
				>Previous</a
			>
			<span>
				<a
					v-for="(pageNumber, index) in pages.slice(currentPage - 1, currentPage + 5)"
					:key="index"
					:class="'paginate_button ' + (currentPage === pageNumber ? 'current' : '')"
					aria-controls="dynvibeTableRules"
					data-dt-idx="1"
					tabindex="0"
					@click="currentPage = pageNumber"
					>{{ pageNumber }}
				</a>
			</span>
			<a
				v-if="currentPage < pages.length"
				:class="'paginate_button next ' + (currentPage < pages.length ? '' : 'disabled')"
				aria-controls="dynvibeTableRules"
				data-dt-idx="2"
				tabindex="-1"
				@click="currentPage++"
				>Next</a
			>
			<a
				:class="'paginate_button next ' + (currentPage < pages.length ? '' : 'disabled')"
				aria-controls="dynvibeTableRules"
				@click="currentPage = pages.length"
				>>></a
			>
		</div>
	</div>
</template>

<script>
export default {
	name: "Datatable",
	props: {
		data: {
			type: Array,
			default: () => [],
		},
		headers: {
			type: Array,
			default: () => [],
		},
		editable: {
			type: Boolean,
			default: true,
		},
		handler: {
			type: Boolean,
			default: false,
		},
		searchable: {
			type: Boolean,
			default: true,
		},
		rulespage: {
			type: Boolean,
			default: true,
		},
		dataManagementPage: {
			type: Boolean,
			default: false,
		},
		displayHandleSolt: {
			type: Boolean,
			default: true,
		},
		displaySelectLanguage: {
			type: Boolean,
			default: true,
		},
		tableSize: {
			type: Number,
			default: 2,
		},
		initialPageSize: {
			type: Number,
			default: 10,
		},
		initialCurrentPage: {
			type: Number,
			default: 1,
		},
	},
	emits: ["deletetranslationrows", "addsubcat", "removesubcat"],
	data() {
		return {
			tableData: [],
			tableFilteredData: [],
			pages: [],
			totalPages: 0,
			pageSize: this.initialPageSize,
			currentPage: this.initialCurrentPage,
			displayedRules: [],
			searchQuery: "",
			key: 0,
			translatedWords: [],
			subcateSelected: "Add subcategories",
		};
	},
	computed: {
		showingStart() {
			return this.currentPage === 1 ? 1 : 1 + (this.currentPage - 1) * this.pageSize;
		},
		showingEnd() {
			return this.displayedRules.length >= this.pageSize ? 1 + (this.pageSize * this.currentPage - 1) : this.tableFilteredData.length;
		},
	},
	watch: {
		data(newVal) {
			this.tableData = [];
			for (let i = 0; i < newVal.length; i++) {
				this.tableData[i] = [newVal[i], i];
			}
			this.search();
		},
		tableData(newVal) {
			if (this.searchQuery.length > 0) {
				this.tableFilteredData = newVal.filter((row) => row[0].join(" ").includes(this.searchQuery));
			} else {
				this.tableFilteredData = newVal;
			}

			this.displayedRules = this.paginate(this.tableFilteredData);

			this.setPages();
		},
		displayedRules() {
			this.setPages();
		},
		pageSize() {
			this.displayedRules = this.paginate(this.tableFilteredData);
			this.currentPage = 1;
			this.setPages();
		},
		currentPage() {
			if (this.dataManagementPage) {
				let countOfRowTranslation = this.tableFilteredData.filter((item) => item[0][4] == "Translation").length;
				if (countOfRowTranslation > 0) {
					this.$emit("deletetranslationrows");
				}
			}

			this.displayedRules = this.paginate(this.tableFilteredData);
			this.setPages();
		},
		searchQuery() {
			this.search();
			this.currentPage = 1;
		},
		translatedWords(newVal) {
			if (newVal.length === 3) {
				this.tableData[this.translatedWords[0]][0][1] = this.translatedWords[1];
				this.tableData[this.translatedWords[0]][0][2] = this.translatedWords[2];
			}
		},
		initialPageSize(newVal) {
			this.pageSize = newVal;
		},
		initialCurrentPage(newVal) {
			this.currentPage = newVal;
		},
	},
	created() {
		this.tableData = [];
		for (let i = 0; i < this.data.length; i++) {
			this.tableData[i] = [this.data[i], i];
		}
		this.tableFilteredData = this.tableData;
		this.setPages();
	},
	mounted() {
		this.displayedRules = this.paginate(this.tableData);
		this.setPages();
	},
	methods: {
		getColumnSize(item) {
			if (this.dataManagementPage) {
				if (item == "NUMBER OF IMPACTED POSTS" || item == "ACTION" || item == "LANGUAGE") {
					return "width: 290px";
				}
			}

			return "width: 561px";
		},
		getLanguage(lang) {
			if (lang != "" && lang != "none") {
				let findLanguage = this.languageslist.find((el) => el.id == lang);

				if (findLanguage != undefined) {
					return findLanguage.value;
				}
			}

			return "";
		},
		addRow(data) {
			let tmp = this.tableData;

			if (data != undefined && Array.isArray(data)) {
				tmp.unshift([data, 0]);
			} else {
				tmp.unshift([[[], [], "none"], 0]);
			}

			for (let i = 0; i < tmp.length; i++) {
				tmp[i][1] = i;
			}

			this.tableData = tmp;
			this.displayedRules = this.paginate(tmp);
			this.key++;
		},
		deleteRow(row) {
			this.tableData.splice(row, 1);
			let tmp = this.tableData;
			for (let i = 0; i < tmp.length; i++) {
				tmp[i][1] = i;
			}
			this.tableData = tmp;
			this.displayedRules = this.paginate(tmp);
			this.key++;
		},
		search() {
			if (this.searchQuery.length > 0) {
				this.tableFilteredData = this.tableData.filter((row) =>
					row[0].join(" ").toLowerCase().includes(this.searchQuery.toLowerCase())
				);
			} else {
				this.tableFilteredData = this.tableData;
			}
			this.displayedRules = this.paginate(this.tableFilteredData);
			this.setPages();
		},
		chooseEditCellFunction(row, column, event) {
			if (this.dataManagementPage) {
				this.editCellDataManagementPage(row, column, event);
			} else {
				this.editCell(row, column, event);
			}
		},
		editCellDataManagementPage(row, column, event) {
			let dataRow = (this.currentPage - 1) * this.pageSize + row;

			if (event.target.innerText && event.target.innerText !== "") {
				if (Array.isArray(this.tableData[dataRow][0][column])) {
					let data = event.target.innerText
						.trim()
						.split(";")
						.filter((el) => el != "");

					event.target.innerText = data.join(";");
					this.tableData[dataRow][0][column] = data;
				} else {
					this.tableData[dataRow][0][column] = event.target.innerText;
				}
			} else {
				if (Array.isArray(this.tableData[dataRow][0][column])) {
					this.tableData[dataRow][0][column] = [];
				} else {
					this.tableData[dataRow][0][column] = "";
				}
			}
		},
		editCell(row, column, event) {
			let dataRow = (this.currentPage - 1) * this.pageSize + row;
			if (event.target.innerText && event.target.innerText !== "") {
				if (column !== 2) {
					let data = event.target.innerText.trim().split(";");
					data = data.map((element) => {
						return element.trim().replace(/\s+/g, " ");
					});
					event.target.innerText = data.join(";");
					this.tableData[dataRow][0][column] = data;
				} else {
					this.tableData[dataRow][0][column] = event.target.innerText;
				}
			} else {
				this.tableData[dataRow][0][column] = [];
			}
		},
		setPages() {
			let numberOfPages = Math.ceil(this.tableFilteredData.length / this.pageSize);
			for (let index = 1; index <= numberOfPages; index++) {
				if (!this.pages.includes(index)) {
					this.pages.push(index);
				}
			}
			if (numberOfPages < this.pages.length) {
				this.pages = this.pages.slice(0, numberOfPages);
			}
		},
		paginate(data) {
			let page = this.currentPage;
			let perPage = this.pageSize;
			let from = page * perPage - perPage;
			let to = page * perPage;

			if (this.dataManagementPage) {
				let countOfRowTranslation = data.slice(from, to + this.pageSize).filter((item) => item[0][4] == "Translation").length;

				if (countOfRowTranslation > 0) {
					return data.slice(from, to + countOfRowTranslation);
				} else {
					return data.slice(from, to);
				}
			} else {
				return data.slice(from, to);
			}
		},
		getData() {
			let out = [];
			const regex = new RegExp(".+");
			for (let i = 0; i < this.tableData.length; i++) {
				if (this.tableData[i][0][0].toString().match(regex) != null) {
					// Check code language
					let langfound = false;
					for (let j = 0; this.languageslist.length > j; j++) {
						if (
							this.tableData[i][0][2] === this.languageslist[j]["id"] ||
							this.tableData[i][0][2] === "none" ||
							this.tableData[i][0][2] === ""
						) {
							langfound = true;
							break;
						}
					}
					if (langfound === false) {
						return { error: "An unknown language code has been entered: " + this.tableData[i][0][2] };
					}

					out[i] = { rule: this.tableData[i][0].slice(0, 2), lang: this.tableData[i][0][2] };
				} else {
					return { error: "Group 1 must be filled" };
				}
			}
			return out;
		},
		getRowColor(row) {
			if (row[0][4] === "Translation") {
				return "background-color: rgba(51, 209, 205, 0.5)";
			}
		},
		onChange(event, index) {
			console.log(event.target.value);
			if (event.target.value != "Add subcategories") {
				this.$emit("addsubcat", { index, value: event.target.value });
			}
		},
	},
};
</script>

<style scoped>
.table_length {
	float: left;
}

.table_search {
	float: right;
}

table.dataTable {
	width: inherit;
	margin: 0 auto;
	clear: both;
	border-collapse: separate;
	border-spacing: 0;
}

table.dataTable thead th,
table.dataTable tfoot th {
	font-weight: bold;
}

table.dataTable thead th,
table.dataTable thead td {
	padding: 10px 18px;
	border-bottom: 1px solid #111;
}

table.dataTable thead th:active,
table.dataTable thead td:active {
	outline: none;
}

table.dataTable tfoot th,
table.dataTable tfoot td {
	padding: 10px 18px 6px 18px;
	border-top: 1px solid #111;
}

table.dataTable tbody tr.selected {
	background-color: #b0bed9;
}

table.dataTable tbody th,
table.dataTable tbody td {
	padding: 8px 10px;
	word-break: break-all;
}

table.dataTable.row-border tbody th,
table.dataTable.row-border tbody td,
table.dataTable.display tbody th,
table.dataTable.display tbody td {
	border-top: 1px solid #ddd;
}

table.dataTable.row-border tbody tr:first-child th,
table.dataTable.row-border tbody tr:first-child td,
table.dataTable.display tbody tr:first-child th,
table.dataTable.display tbody tr:first-child td {
	border-top: none;
}

table.dataTable.cell-border tbody th,
table.dataTable.cell-border tbody td {
	border-top: 1px solid #ddd;
	border-right: 1px solid #ddd;
}

table.dataTable.cell-border tbody tr th:first-child,
table.dataTable.cell-border tbody tr td:first-child {
	border-left: 1px solid #ddd;
}

table.dataTable.cell-border tbody tr:first-child th,
table.dataTable.cell-border tbody tr:first-child td {
	border-top: none;
}

table.dataTable.stripe tbody tr.odd,
table.dataTable.display tbody tr.odd {
	background-color: #f9f9f9;
}

table.dataTable.stripe tbody tr.odd.selected,
table.dataTable.display tbody tr.odd.selected {
	background-color: #acbad4;
}

table.dataTable.hover tbody tr:hover,
table.dataTable.display tbody tr:hover {
	background-color: #f6f6f6;
}

table.dataTable.hover tbody tr:hover.selected,
table.dataTable.display tbody tr:hover.selected {
	background-color: #aab7d1;
}

table.dataTable.no-footer {
	border-bottom: 1px solid #111;
}

table.dataTable.nowrap th,
table.dataTable.nowrap td {
	white-space: nowrap;
}

table.dataTable.compact thead th,
table.dataTable.compact thead td {
	padding: 4px 17px 4px 4px;
}

table.dataTable.compact tfoot th,
table.dataTable.compact tfoot td {
	padding: 4px;
}

table.dataTable.compact tbody th,
table.dataTable.compact tbody td {
	padding: 4px;
}

table.dataTable th.dt-left,
table.dataTable td.dt-left {
	text-align: left;
}

table.dataTable th.dt-center,
table.dataTable td.dt-center,
table.dataTable td.dataTables_empty {
	text-align: center;
}

table.dataTable th.dt-right,
table.dataTable td.dt-right {
	text-align: right;
}

table.dataTable th.dt-justify,
table.dataTable td.dt-justify {
	text-align: justify;
}

table.dataTable th.dt-nowrap,
table.dataTable td.dt-nowrap {
	white-space: nowrap;
}

table.dataTable thead th.dt-head-left,
table.dataTable thead td.dt-head-left,
table.dataTable tfoot th.dt-head-left,
table.dataTable tfoot td.dt-head-left {
	text-align: left;
}

table.dataTable thead th.dt-head-center,
table.dataTable thead td.dt-head-center,
table.dataTable tfoot th.dt-head-center,
table.dataTable tfoot td.dt-head-center {
	text-align: center;
}

table.dataTable thead th.dt-head-right,
table.dataTable thead td.dt-head-right,
table.dataTable tfoot th.dt-head-right,
table.dataTable tfoot td.dt-head-right {
	text-align: right;
}

table.dataTable thead th.dt-head-justify,
table.dataTable thead td.dt-head-justify,
table.dataTable tfoot th.dt-head-justify,
table.dataTable tfoot td.dt-head-justify {
	text-align: justify;
}

table.dataTable thead th.dt-head-nowrap,
table.dataTable thead td.dt-head-nowrap,
table.dataTable tfoot th.dt-head-nowrap,
table.dataTable tfoot td.dt-head-nowrap {
	white-space: nowrap;
}

table.dataTable tbody th.dt-body-left,
table.dataTable tbody td.dt-body-left {
	text-align: left;
}

table.dataTable tbody th.dt-body-center,
table.dataTable tbody td.dt-body-center {
	text-align: center;
}

table.dataTable tbody th.dt-body-right,
table.dataTable tbody td.dt-body-right {
	text-align: right;
}

table.dataTable tbody th.dt-body-justify,
table.dataTable tbody td.dt-body-justify {
	text-align: justify;
}

table.dataTable tbody th.dt-body-nowrap,
table.dataTable tbody td.dt-body-nowrap {
	white-space: nowrap;
}

table.dataTable,
table.dataTable th,
table.dataTable td {
	box-sizing: content-box;
}

.dataTables_wrapper {
	position: relative;
	clear: both;
	*zoom: 1;
	zoom: 1;
}

.dataTables_wrapper select,
.dataTables_wrapper input {
	height: 25px;
	padding: 0px 4px 0px 7px;
	border-radius: 12px;
	font-size: 13px;
	border: 1px solid #e5e5e5;
	color: #cacaca;
	outline: 0;
	box-shadow: none;
}

.dataTables_wrapper .dataTables_length {
	float: left;
}

.dataTables_wrapper .dataTables_filter {
	float: right;
	text-align: right;
}

.dataTables_wrapper .dataTables_filter input {
	margin-left: 0.5em;
}

.dataTables_wrapper .dataTables_info {
	clear: both;
	float: left;
	padding-top: 0.755em;
}

.dataTables_wrapper .dataTables_paginate {
	float: right;
	text-align: right;
	padding-top: 0.25em;
}

.dataTables_wrapper .dataTables_paginate .paginate_button {
	box-sizing: border-box;
	display: inline-block;
	min-width: 1.5em;
	padding: 0.5em 1em;
	margin-left: 2px;
	text-align: center;
	text-decoration: none !important;
	cursor: pointer;
	*cursor: hand;
	color: #333 !important;
	border: 1px solid transparent;
	border-radius: 2px;
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current,
.dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
	color: #333 !important;
	border: 1px solid #979797;
	background-color: white;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #fff), color-stop(100%, #dcdcdc));
	background: -webkit-linear-gradient(top, #fff 0%, #dcdcdc 100%);
	background: -moz-linear-gradient(top, #fff 0%, #dcdcdc 100%);
	background: -ms-linear-gradient(top, #fff 0%, #dcdcdc 100%);
	background: -o-linear-gradient(top, #fff 0%, #dcdcdc 100%);
	background: linear-gradient(to bottom, #fff 0%, #dcdcdc 100%);
}

.dataTables_wrapper .dataTables_paginate .paginate_button.disabled,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:hover,
.dataTables_wrapper .dataTables_paginate .paginate_button.disabled:active {
	cursor: default;
	color: #666 !important;
	border: 1px solid transparent;
	background: transparent;
	box-shadow: none;
}

.dataTables_wrapper .dataTables_paginate .paginate_button:hover {
	color: white !important;
	border: 1px solid #111;
	background-color: #585858;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #585858), color-stop(100%, #111));
	background: -webkit-linear-gradient(top, #585858 0%, #111 100%);
	background: -moz-linear-gradient(top, #585858 0%, #111 100%);
	background: -ms-linear-gradient(top, #585858 0%, #111 100%);
	background: -o-linear-gradient(top, #585858 0%, #111 100%);
	background: linear-gradient(to bottom, #585858 0%, #111 100%);
}

.dataTables_wrapper .dataTables_paginate .paginate_button:active {
	outline: none;
	background-color: #2b2b2b;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, #2b2b2b), color-stop(100%, #0c0c0c));
	background: -webkit-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
	background: -moz-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
	background: -ms-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
	background: -o-linear-gradient(top, #2b2b2b 0%, #0c0c0c 100%);
	background: linear-gradient(to bottom, #2b2b2b 0%, #0c0c0c 100%);
	box-shadow: inset 0 0 3px #111;
}

.dataTables_wrapper .dataTables_paginate .ellipsis {
	padding: 0 1em;
}

.dataTables_wrapper .dataTables_processing {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 100%;
	height: 40px;
	margin-left: -50%;
	margin-top: -25px;
	padding-top: 20px;
	text-align: center;
	font-size: 1.2em;
	background-color: white;
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		color-stop(0%, rgba(255, 255, 255, 0)),
		color-stop(25%, rgba(255, 255, 255, 0.9)),
		color-stop(75%, rgba(255, 255, 255, 0.9)),
		color-stop(100%, rgba(255, 255, 255, 0))
	);
	background: -webkit-linear-gradient(
		left,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.9) 25%,
		rgba(255, 255, 255, 0.9) 75%,
		rgba(255, 255, 255, 0) 100%
	);
	background: -moz-linear-gradient(
		left,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.9) 25%,
		rgba(255, 255, 255, 0.9) 75%,
		rgba(255, 255, 255, 0) 100%
	);
	background: -ms-linear-gradient(
		left,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.9) 25%,
		rgba(255, 255, 255, 0.9) 75%,
		rgba(255, 255, 255, 0) 100%
	);
	background: -o-linear-gradient(
		left,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.9) 25%,
		rgba(255, 255, 255, 0.9) 75%,
		rgba(255, 255, 255, 0) 100%
	);
	background: linear-gradient(
		to right,
		rgba(255, 255, 255, 0) 0%,
		rgba(255, 255, 255, 0.9) 25%,
		rgba(255, 255, 255, 0.9) 75%,
		rgba(255, 255, 255, 0) 100%
	);
}

.dataTables_wrapper .dataTables_length,
.dataTables_wrapper .dataTables_filter,
.dataTables_wrapper .dataTables_info,
.dataTables_wrapper .dataTables_processing,
.dataTables_wrapper .dataTables_paginate {
	color: #333;
}

.dataTables_wrapper .dataTables_scroll {
	clear: both;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody {
	*margin-top: -1px;
	-webkit-overflow-scrolling: touch;
}
e .dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td {
	vertical-align: middle;
}

.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > thead > tr > td > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > th > div.dataTables_sizing,
.dataTables_wrapper .dataTables_scroll div.dataTables_scrollBody > table > tbody > tr > td > div.dataTables_sizing {
	height: 0;
	overflow: hidden;
	margin: 0 !important;
	padding: 0 !important;
}

.dataTables_wrapper.no-footer .dataTables_scrollBody {
	border-bottom: 1px solid #111;
}

.dataTables_wrapper.no-footer div.dataTables_scrollHead table.dataTable,
.dataTables_wrapper.no-footer div.dataTables_scrollBody > table {
	border-bottom: none;
}

.dataTables_wrapper:after {
	visibility: hidden;
	display: block;
	content: "";
	clear: both;
	height: 0;
}

@media screen and (max-width: 767px) {
	.dataTables_wrapper .dataTables_info,
	.dataTables_wrapper .dataTables_paginate {
		float: none;
		text-align: center;
	}

	.dataTables_wrapper .dataTables_paginate {
		margin-top: 0.5em;
	}
}

@media screen and (max-width: 640px) {
	.dataTables_wrapper .dataTables_length,
	.dataTables_wrapper .dataTables_filter {
		float: none;
		text-align: center;
	}

	.dataTables_wrapper .dataTables_filter {
		margin-top: 0.5em;
	}
}
select.dynvibeListMastertags {
	color: #8e8e8e;
	background-color: transparent;
	width: 100%;
	max-width: none;
	min-width: 0;
	border: none;
	font-size: 14px;
	font-weight: normal;
}
.dynvibeAddSubCategory {
	margin-right: 0px;
	margin-top: 3px;
}
.dynvibeRuleSepRemove {
	background-color: #8e8e8e;
	color: white;
}
</style>
