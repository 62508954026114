<template>
	<div v-if="wrapperClasses.length > 0" :class="[wrapperClasses]">
		<select v-model="selected" :class="[insideClasses]" :style="{ width: width ? width + '%' : '100%' }" :disabled="disabled">
			<option v-if="placeholder" value="">{{ placeholder }}</option>
			<slot></slot>
			<option v-for="(option, index) in options" :key="index" :value="reduce(option)">
				{{ getOptionLabel(option) }}
			</option>
		</select>
	</div>
	<select v-else v-model="selected" :class="[insideClasses]" :disabled="disabled">
		<option v-if="placeholder" value="">{{ placeholder }}</option>
		<slot></slot>
		<option v-for="(option, index) in options" :key="index" :value="reduce(option)">
			{{ getOptionLabel(option) }}
		</option>
	</select>
</template>

<script>
export default {
	name: "GenericSelect",
	props: {
		width: {
			type: Number,
			default: 100,
		},
		wrapperClasses: {
			type: Array,
			default: () => [],
		},
		insideClasses: { type: Array, default: () => [] },
		/**
		 * Contains the currently selected value. Very similar to a
		 * `value` attribute on an <input>. You can listen for changes
		 * with the 'input' event.
		 * @type {Object||String||null}
		 */
		// eslint-disable-next-line vue/require-default-prop,vue/require-prop-types
		value: {},

		/**
		 * An array of strings or objects to be used as dropdown choices.
		 * If you are using an array of objects, vue-select will look for
		 * a `label` key (ex. [{label: 'This is Foo', value: 'foo'}]). A
		 * custom label key can be set with the `label` prop.
		 * @type {Array}
		 */
		options: {
			type: [Array, Object],
			default() {
				return [];
			},
		},
		/**
		 * Disable the entire component.
		 * @type {Boolean}
		 */
		disabled: {
			type: Boolean,
			default: false,
		},

		/**
		 * Equivalent to the `placeholder` attribute on an `<input>`.
		 * @type {String}
		 */
		placeholder: {
			type: String,
			default: "",
		},

		/**
		 * Enables/disables clearing the search text when an option is selected.
		 * @type {Boolean}
		 */
		clearSearchOnSelect: {
			type: Boolean,
			default: true,
		},
		/**
		 * Close a dropdown when an option is chosen. Set to false to keep the dropdown
		 * open (useful when combined with multi-select, for example)
		 * @type {Boolean}
		 */
		closeOnSelect: {
			type: Boolean,
			default: true,
		},
		/**
		 * Tells vue-select what key to use when generating option
		 * labels when each `option` is an object.
		 * @type {String}
		 */
		label: {
			type: String,
			default: "label",
		},
		getOptionLabel: {
			type: Function,
			default: (option) => option,
		},

		/**
		 * When working with objects, the reduce
		 * prop allows you to transform a given
		 * object to only the information you
		 * want passed to a v-model binding
		 * or @input event.
		 */
		reduce: {
			type: Function,
			default: (option) => option,
		},
	},
	emits: ["update"],
	data() {
		return {
			filteredOptions: [],
			selected: "",
		};
	},
	watch: {
		selected(val) {
			this.$emit("update", val);
		},
		value(val) {
			this.selected = val;
		},
	},
	mounted() {
		if (this.value) {
			this.selected = this.value;
		}
	},

	created() {
		this.filteredOptions = this.options;
	},
};
</script>

<style></style>
