<template>
	<footer id="footer" class="row" :style="{ left: ACL.isClient() ? '0px' : '120px' }">
		<p class="col-auto me-auto" style="display: flex; float: left; align-items: center; color: #737373">
			<i class="icon-logo-text" style="font-size: 22px; margin-right: 10px"></i>
			copyright &copy; <span class="date">{{ date }}</span>
		</p>
		<p class="col-auto" style="float: right"><a href="https://www.dynvibe.com" rel="external">www.dynvibe.com</a></p>
	</footer>
</template>

<script>
import { ACL } from "@/acl";

export default {
	name: "Footer",
	data() {
		return {
			date: "",
		};
	},
	computed: {
		ACL() {
			return ACL;
		},
	},
	created() {
		this.date = new Date().getFullYear();
	},
};
</script>

<style scoped>
#footer {
	z-index: 1;
	bottom: 0;
	position: fixed;
	right: 35px;
}
</style>
